import { render, staticRenderFns } from "./button.vue?vue&type=template&id=ffcd8d38&scoped=true&lang=pug&"
import script from "./button.vue?vue&type=script&lang=coffee&"
export * from "./button.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffcd8d38",
  null
  
)

export default component.exports