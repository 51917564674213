











export default

	# Reload the page
	methods: reload: -> location.reload()

