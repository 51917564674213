















import Modal from './modal'
import videoMetrics from 'library/mixins/video-metrics'

export default

	mixins: [ videoMetrics ]

	components: {
		Modal
	}

	props:
		videoUrl: String
		uploadedVideo: String
		alt: String
		preview: String

	data: -> player: null

	mounted: ->
		if @uploadedVideo then @initMp4Video()
		else
			@$nextTick ->
				if window.YT then @loadVideo() else @initScript()

	computed:
		videoId: ->
			return null unless @videoUrl
			id = @videoUrl.split('v=')[1]
			ampersand = id.indexOf '&'
			if ampersand != -1 then id = id.substring 0, ampersand
			return id

	methods:
		initMp4Video: ->
			# Set default volume not to 100%
			@$refs.embeddedVideo.volume = 0.3

			# Video Metrics
			@$refs.embeddedVideo.addEventListener 'timeupdate', @onVideoTimeUpdate

		# Create iframe API and watch for it to be ready
		initScript: ->
			# Listen for complete
			window.onYouTubeIframeAPIReady = =>
				@loadVideo()

			# Write the tag
			tag = document.createElement 'script'
			tag.src = 'https://www.youtube.com/iframe_api'
			document.body.appendChild tag

		# Create a player instance
		loadVideo: ->
			# Remove existing player (just for safety)
			if @player
				@player.destroy()
				@player = null
				console.warn 'video loadVideo: found existing player, had to destroy it.'
			@player = new YT.Player 'video-container',
				videoId: @videoId,
				playerVars:
					rel: 0
				events:
					'onReady': @onPlayerReady
			window.player = @player

		onPlayerReady: (event) ->
			event.target.playVideo()

		stopVideo: ->
			@player.stopVideo()

		handleDestroy: ->
			if @player
				@player.destroy()
				@player = null
			@$destroy()
			@$el.remove()

