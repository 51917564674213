






























import Price from 'library/components/global/price'
export default

	name: 'VariantRow'

	components: { Price }

	props:
		variant: Object

	computed:

		# Support both GQL and liquid price names
		unitPrice: ->
			return unless @unitCount > 1
			return (@variant.price.amount || @variant.price) / @unitCount

		unitCount: -> switch

			# Look for a variant title that is like "36 bars" and return the 36
			when matches = @variantTitle?.match /(\d+) bars/i
			then parseInt matches[1]

			# If the sku ends with a number, like 160004E-BX-EA-12, return that number
			when matches = @variant.sku?.match /\-(\d+)$/i
			then parseInt matches[1]

		# Support both GQL and liquid variant names
		variantTitle: -> @variant.name || @variant.title || @variant.option1
		variantImage: -> @variant.featuredImage

	methods:
		pickRow: -> @$emit 'variantpicked', @variant
