
















import LayoutFooter from 'library/components/layout/footer/footer'
import LayoutHeader from '~/components/layout/header'
import icomoonWoff from 'library/styles/fonts/icomoon/fonts/icomoon.woff'
import { getCurrentLanguage, getCurrentCountry } from 'library/services/i18n/locales'

export default
	name: 'DefaultLayout'

	head: ->

		# Assemble data
		store = @$store.state.globals.meta || {}

		# Price Spider meta tags if international
		priceSpiderTags = if @$cartEnabled then []
		else [
			@$metaTag 'ps-key', '5186-61d8dbafa66ba6001ac351ab'
			@$metaTag 'ps-country', getCurrentCountry()
			@$metaTag 'ps-language', getCurrentLanguage()
		]

		# Meta tags
		meta: @$nonEmpty [

			# Write default meta tags
			@$metaTag 'description', store.metaDescription
			@$metaTag 'og:image', store.metaImage

			# Adds the pricespider meta tags on internationl sites
			...priceSpiderTags
		]

		# Link tags
		link: [

			# Preload icomoon
			{
				rel: 'preload'
				as: 'font'
				href: icomoonWoff
				crossorigin: 'anonymous'
			}

		]

	components: {
		LayoutFooter
		LayoutHeader
	}


