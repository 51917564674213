




















export default
	name: 'productSubscriptionFrequency'

	props:
		sellingPlanAllocations: Array

		value: Object | Boolean

	data: ->
		choice: @value
		id: null

	mounted: ->
		@choice = @productFrequencies[0]
		@id = @_uid

	computed:
		productFrequencies: ->
			@sellingPlanAllocations.map(({sellingPlan}) =>
				return {
					...sellingPlan
					...@parseSellingPlanName sellingPlan.name
				}
			)
				.sort((a,b) => a.every - b.every)
				.sort((a,b) => a.every_period - b.every_period)

	watch:
		choice: (choice) -> @$emit 'input', choice
		productFrequencies: ->
			if !@choice then @choice = @productFrequencies[0]

	methods:

		# Parse the selling plan name frequency numbers like ordergroove expects.
		# This turns "Delivered every 2 MONTH(s)" into every: 2, every_period: 3
		# Note the interval may be missing in the case of "Delivered every day"
		# https://regex101.com/r/iOqCpq/3
		# https://og-restrpc.readme.io/docs/domain-objects
		parseSellingPlanName: (name) ->
			unless matches = name.match /every (\d+ )?(\w+)/i
			then throw "Unparseable selling plan name: #{name}"
			[all, interval = 1, period ] = matches
			periodIndex = switch
				when period.match /day/i then 1
				when period.match /week/i then 2
				when period.match /month/i then 3
				else throw "Unparseable selling plan period: #{period}"
			return {
				every: parseInt interval
				every_period: periodIndex
			}

		makeOptionName: (sellingPlan) ->
			periodName = switch sellingPlan.every_period
				when 1 then 'day'
				when 2 then 'week'
				when 3 then 'month'

			frequency = switch sellingPlan.every
				when 1 then 'Every'
				else sellingPlan.every

			plural = if !(sellingPlan.every == 1) then "s" else ""

			"#{frequency} #{periodName}#{plural}"
