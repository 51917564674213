import getBundledVariants from 'library/compiled/bundled-variants'

import intersectionWith from 'lodash/intersectionWith'

// Helper function to see if argument is actually an object
// Used to check $storefront and shopifyProduct
export const objectExists = (object) => {
	return typeof object === 'object' &&
		!Array.isArray(object) &&
		object !== null
}


// Fetch data for bundled variants with a single network request
// Accepts: Array of variant ids, object containing our $storefront object
// Returns: Array of variants
export const fetchVariants = async (variantIds, {$storefront}) => {

	// Escape if $storefront was not passed
	if (!$storefront) return []

	// Deconstruct variants from query data
	let { variants } = await $storefront.execute({
		query: getBundledVariants,
		variables: {
			variantIds
		}
	})

	return variants
}


export const makeBundlePrice = (bundleVariants) => {
	return bundleVariants.reduce((total, variant) => {
		let variantPrice = variant.price?.amount || variant.price
		let variantQuantity = variant.count
		return total + Number(variantPrice) * Number(variantQuantity)
	}, 0)
}


export const makeBundleCompareAtPrice = (bundleVariants) => {
	return bundleVariants.reduce((total, variant) => {
		let variantCompareAt = variant.compareAtPrice ?
			variant.compareAtPrice?.amount || variant.compareAtPrice :
			variant.price?.amount || variant.price
		let variantQuantity = variant.count
		return total + Number(variantCompareAt) * Number(variantQuantity)
	}, 0)
}


// Get a better variant title based on parsed variant count data combined with
// the count of variant in the bundle
export const makeBundleVariantTitle = (bundleVariants) => {

	let barCount = bundleVariants.reduce((count, variant) => {
		let barCountRegex = /(\d+) bars/i
		let singleRegex = /single/i
		// If we've hit a variant that we can't parse, then count is set to undefined
		if (count == undefined) { return count }

		let variantTitle = variant.title
		let barsInVariant

		// There is no title for the variant
		if (!variantTitle) {
			return
		}

		// Format of "X bars"
		else if (variantTitle.match(barCountRegex)) {
			let barCount = variantTitle.match(barCountRegex)[1]
			barsInVariant = parseInt(barCount)
		}

		// Format of "single"
		else if (variantTitle.match(singleRegex)) {
			barsInVariant = 1
		}

		// We've hit a case where a title couldn't be parsed, break
		else {
			return
		}

		// return our count (num of variant in bundle) times the bars in the variant
		return variant.count * barsInVariant + count
	}, 0)

	if (!barCount) { return "Bundle" }

	return `${barCount} bars`

}
// Create a spoofed selling plan allocation for the bundle based on children
export const makeBundleSellingPlanAllocations = (bundleVariants) => {
	let variantSellingPlans = bundleVariants.map((product) => {return product.sellingPlanAllocations})
	return intersectionWith(...variantSellingPlans, (a,b) => {
		return a.sellingPlan.id == b.sellingPlan.id
	})
}

// JS version of `twoDecimals` from helpers.coffee
export const twoDecimals = (num) => num.toFixed(2)
