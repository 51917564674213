import { ONETRUST_CATEGORIES } from './onetrust-constants'

# Add classes to iframes to gate them based on OneTrust consent
export consentBlockIframes = (html) ->
	return html unless html.includes '<iframe'

	# OneTrust is currently only enabled on US sites
	return html unless process.env.CMS_SITE in ['prod', 'uat', 'dev']

	# Get DOM parser on server or client and parse the html
	if process.server
		{ JSDOM } = require 'jsdom'
		dom = new JSDOM html
		doc = dom.window.document
	else if typeof window != 'undefined'
		parser = new DOMParser()
		doc = parser.parseFromString html, 'text/html'
	else
		console.warn 'consentBlockIframes not run'
		return html

	# Find all iframes
	for iframe in doc.querySelectorAll 'iframe'

		# Change 'src' attribute to 'data-src'
		src = iframe.getAttribute 'src'
		iframe.setAttribute 'data-src', src
		iframe.removeAttribute 'src'

		# Decide which cookie category to use
		cookieCategory = getCookieCategoryFromIframeSrc src
		unless consentId = ONETRUST_CATEGORIES[cookieCategory.toUpperCase()]
			console.warn "cookieCategory #{cookieCategory} could not be resolved"
			return html

		# Add CSS class with the OneTrust category
		iframe.classList.add "optanon-category-#{consentId}"

		# Add fallback message after the iframe
		fallback = doc.createElement 'div'
		fallback.classList.add 'onetrust-fallback'
		fallback.innerHTML = """
			Content was hidden because you have disabled "#{cookieCategory}" cookies.
			<a href='javascript:window.OneTrust.ToggleInfoDisplay()'>Click here</a>
			to adjust your cookie&nbsp;settings.
		"""
		iframe.parentNode.insertBefore fallback, iframe.nextSibling

	# Convert back to a string
	if process.server then dom.serialize()
	else new XMLSerializer().serializeToString doc

# Convert iframe srcs to cookie consent categories
getCookieCategoryFromIframeSrc = (src) -> switch
	when src.includes 'issuu.com' then 'functional'
	else 'targeting'
