



















export default
	name: 'image-link'

	props:
		item: Object
