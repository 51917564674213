


import { getAssetObject } from 'library/components/global/visual/craft-visual'
import { makeImgixUrl } from 'library/services/helpers'
export default
	name: 'ResponsiveTile'

	props: {

		# Make explicit desktop and mobile props
		desktopImage: Object|Array
		mobileImage: Object|Array

		# Also make a shorthand, for passing a supertable images object
		image: Array

		# How to repeat
		repeat: String # Any valid background-repeat prop
		position: String # Any backgound-position value
	}

	computed:

		# The asset that will be shown for the breaks
		desktop: -> @getAsset 'desktop'
		mobile: -> @getAsset 'mobile'
		desktopPath: -> makeImgixUrl @desktop
		mobilePath: -> makeImgixUrl @mobile

		# Make the styles that render will contain the responsive asset. Including
		# a fallabck for devices that don't support css vars
		styles: ->
			backgroundRepeat: @repeat
			backgroundPosition: @position
			backgroundImage: "url('#{@desktopPath || @mobilePath}')" # Fallback
			'--desktop-url': "url('#{@desktopPath || @mobilePath}')"
			'--mobile-url': "url('#{@mobilePath || @desktopPath}')"

	methods:

		# Get the specified asset
		# viewportType: mobile|desktop
		getAsset: (viewportType) ->

			# Get from supertable shorthand object
			if superTableAsset = getAssetObject @image
				return getAssetObject superTableAsset[viewportType]

			# Get explicit value
			else return getAssetObject @[viewportType + 'Image']

	# Create a div with the background image for the width
	render: (create) ->
		return unless @desktop || @mobile
		create 'div',
			staticClass: 'responsive-tile'
			style: @styles

