# Implement keyboard support for comboboxes based on
# https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/

# Needs $Refs of combo and listbox and options

SelectActions = {
	Close: 0
	CloseSelect: 1
	First: 2
	Last: 3
	Next: 4
	Open: 5
	PageDown: 6
	PageUp: 7
	Previous: 8
	Select: 9
	Type: 10
}

export default

	data: ->
		loaded: false
		# How much to scroll when using page up/down
		pageSize: 10

		open: false
		options: []
		activeIdx: 0
		selectedIdx: @activeIdx || 0

		uniqueId: 'combo'

		# How long till a search resets
		timeoutDuration: 500
		searchString: ''
		searchTimeout: null

	mounted: ->
		# Set our unique id to the vue assigned unique id
		@uniqueId = @_uid
		@loaded = true

	computed:
		maxIndex: -> @options?.length - 1

		comboAttributes: ->
			role: "combobox"
			"aria-expanded": @open
			"aria-activedescendant": @makeOptionId @activeIdx
			"aria-autocomplete": "none"
			"aria-controls": @listboxId
			"tabindex": 0

		comboActions: ->
			'keydown': @onComboKeydown
			'click': @onComboClick
			'blur': @onComboBlur

		listboxAttributes: ->
			role: "listbox"
			"data-open": @open
			id: @listboxId

		listboxId: -> "#{@uniqueId}-listbox"

		currentOption: ->
			@options[@activeIdx]


	methods:
		onComboClick: -> @updateMenuState(!@open, false)

		onComboKeydown: (e) ->
			{ key } = e

			action = @getActionFromKey(e)

			switch action
				when SelectActions.Last, SelectActions.First
					@updateMenuState(true)
				when SelectActions.Next, SelectActions.Previous, SelectActions.PageUp, SelectActions.PageDown
					e.preventDefault()
					return @selectOption @getUpdatedIndex action
				when SelectActions.CloseSelect
					e.preventDefault()
					@chooseOption()
				when SelectActions.Close
					e.preventDefault()
					return @updateMenuState false
				when SelectActions.Type
					return @onComboType key
				when SelectActions.Open
					e.preventDefault()
					return @updateMenuState true

		onComboType: (letter) ->
			# Open the listbox if it is closed
			@updateMenuState true

			# Find the index of the first matching option
			searchString = @getSearchString letter
			searchIndex = @getIndexByLetter(searchString, @activeIdx + 1)

			# If a match was found, go to it
			if searchIndex >= 0 then @selectOption searchIndex

			else
				@clearTimeout
				@searchString = ''

		onComboBlur: (e) ->
			# Do nothing if relatedTarget is contained within listbox
			if @$refs.listbox?.contains(e.relatedTarget) then return
			# Select option and close
			if @open
				@chooseOption()
				@updateMenuState(false, false)

		updateMenuState: (open, callFocus = true) ->
			if @open == open then return

			# Update State
			@open = open

			# Move focuse back to the combobox, if needed (like closing)
			callFocus and @$refs.combo.focus()


		# Map Keypress to action; takes into account open state
		getActionFromKey: (e) ->
			{key, altKey, ctrlKey, metaKey} = e

			# Keys that will do the default open action
			openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']

			# Handle opening when closed
			if !@open and openKeys.includes key then return SelectActions.Open

			# Home and end keys move the selected option when open or closed
			if key == 'Home' then return SelectActions.First
			if key == 'End' then return SelectActions.Last

			# Handle typing characters when open or closed
			if (
				key == 'Backspace' or
				key == 'Clear' or
				(key.length == 1 and key != ' ' and !altKey and !ctrlKey and !metaKey)
			) then return SelectActions.Type

			# Handle Keys when open
			if @open
				if key == 'ArrowUp' and altKey then return SelectActions.CloseSelect
				else if key == 'ArrowDown' and !altKey then return SelectActions.Next
				else if key == 'ArrowUp' then return SelectActions.Previous
				else if key == 'PageUp' then return SelectActions.PageUp
				else if key == 'PageDown' then return SelectActions.PageDown
				else if key == 'Escape' then return SelectActions.Close
				else if key== 'Enter' or key == ' ' then return SelectActions.CloseSelect

		# Return the index of an option from an array of options, based on a search string
		# if the filter is multiple iterations of the same letter (e.g. "aaa"),
		# then cycle through first-letter matches
		getIndexByLetter: (filter, startIndex=0) ->
			orderedOptions = [
				...@options.slice startIndex
				...@options.slice 0, startIndex
			]

			firstMatch = @filterOptions(orderedOptions, filter)[0]
			allSameLetter = (array) -> array.every((letter) -> letter == array[0])

			# first check if there is an exact match for the typed string
			if !!firstMatch then return @options.indexOf firstMatch

			# if the same letter is being repeated, cycle through first-letter matches
			else if allSameLetter(filter.split(''))
				matches = @filterOptions(orderedOptions, filter[0])
				return @options.indexOf matches[0]

			# If no matches, return -1
			else
				return -1

		# Filter an array of options against an input string
		# returns an array of options that begin with the filter string, case-independent
		filterOptions: (options=[], filter, exclude=[]) ->
			return options.filter((option) ->
				# Use option.value or option.name if provided, otherwise use option
				optionValue = option?.name || option
				matches = optionValue.toLowerCase().indexOf(filter.toLowerCase()) == 0
				return matches and exclude.indexOf(option) < 0
			)

		getUpdatedIndex: (action) ->
			switch action
				when SelectActions.First
					return 0
				when SelectActions.Last
					return @maxIndex
				when SelectActions.Previous
					return Math.max(0, @selectedIdx - 1);
				when SelectActions.Next
					return Math.min(@maxIndex, @selectedIdx + 1);
				when SelectActions.PageUp
					return Math.max(0, @selectedIdx - pageSize);
				when SelectActions.PageDown
					return Math.min(@maxIndex, @selectedIdx + pageSize);
				else
					return @selectedIdx;

		# Check if the element is visible in browser viewport
		isElementInView: (element) ->
			bounding = element.getBoundingClientRect()

			return (
				bounding.top >= 0 and
				bounding.left >= 0 and
				bounding.bottom <=
					(window.innerHeight or document.documentElement.clientHeight) and
				bounding.right <=
					(window.innerWidth or document.documentElement.clientWidth)
			)

		# Check if an element is currently scrollable
		isScrollable: (element) ->
			return element and element.clientHeight < element.scrollHeight

		# Ensure a given child element is within the parent's visible scroll area
		# if the child is not visible, scroll the parent
		maintainScrollVisibility: (activeElement, scrollParent) ->
			{ offsetHeight, offsetTop } = activeElement
			{ offsetHeight: parentOffsetHeight, scrollTop } = scrollParent

			isAbove = offsetTop < scrollTop
			isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight

			if isAbove
				scrollParent.scrollTo(0, offsetTop)
			else if isBelow
				scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight)

		## OPTIONS HELPERS ##
		onOptionClick: (idx) ->
			@selectOption idx
			@chooseOption idx
			@updateMenuState false

		# Clicking will cause a blur event,
		# but we don't want to perform the default keyboard blur action
		onOptionMouseDown: -> @ignoreBlur = true

		# Update our selected option NOT ACTIVATED THOUGH
		selectOption: (idx) ->
			@selectedIdx = idx

			if @isScrollable @$refs.listbox
				@maintainScrollVisibility @$refs.option[idx], @$refs.listbox

		# Make the selected idx the active idx
		chooseOption: ->
			@activeIdx = @selectedIdx
			@updateMenuState(false)

		# Build out attributes for the option based on index
		getOptionAttributes: (optionIdx) ->
			role: "option"
			"aria-selected": @selectedIdx == optionIdx
			id: @makeOptionId(optionIdx)

		getOptionEvents: (optionIdx) ->
			click: () => @onOptionClick optionIdx
			mouseover: () => @selectOption optionIdx

		isActiveOption: (optionIdx) -> @activeIdx == optionIdx

		# Build an id based on option index
		makeOptionId: (idx) -> "#{@uniqueId}-#{@activeIdx}"


		## SEARCH FUNCTIONS ##

		getSearchString: (char) ->
			# reset typing timeout and start new timeout
			# this allows us to make multi-letter matches, like a native select
			if !!@searchTimeout then clearTimeout @searchTimeout

			@searchTimeout = setTimeout(()->
				@searchString = ''
			, @timeoutDuration)

			# Add most recent letter to saved search string
			return @searchString += char



	# watch:
		# Watch for option change and scroll if needed
		# activeIdx: (idx) ->
		# 	return unless idx and @loaded
		# 	# ensure new item is in view
		# 	if @$refs.listbox and @isScrollable(@$refs.listbox)
		# 		@maintainScrollVisibility(@$refs.option[idx], @$refs.listbox)


