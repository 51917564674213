






















import breakpoints from 'library/styles/vars/breakpoints.json'
import SsrCarousel from 'vue-ssr-carousel'
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

export default

	components: { SsrCarousel }

	props:
		value: Number # For v-model support
		config:
			type: Object
			default: -> {}
		notResponsive:
			type: Boolean
			default: false

	computed:

		mergedConfig: -> {

			# Standard config options
			showDots: true
			showArrows: true
			slidesPerPage: 4
			gutter: 40

			# Flickity implementation had free scroll,
			# but would only show dots based on page count, not slide count
			# paginateBySlide: true

			# Responsive config options
			responsive: [
				{
					maxWidth: 1190
					slidesPerPage: 3
				}
				{
					maxWidth: parseInt breakpoints['tablet-landscape'] # 1024
					slidesPerPage: 2
				}
				{
					maxWidth: parseInt breakpoints.tablet # 768
					slidesPerPage: 1
				}
			] unless @notResponsive

			...@config # Merge in passed in config options

			on: {
				...(@config.on || {}),
				change: (index) =>
					@config.on?.change?(index)
					@$emit 'change', index
			}

		}

	methods:

		# Prevent clicks on JS listeners from working while dragging
		onClick: (e) ->
			if @dragging
				e.preventDefault()
				e.stopPropagation()

		goToIndex: (idx) ->
			@$refs.carousel.goto idx
