# Map Shopify media objects into params for visual
import Visual from 'vue-visual'
import { defaultPlaceholderColor, sizesHelpers } from './craft-visual'
export PRODUCT_ASPECT = 1320 / 1076
export default
	name: 'ShopifyVisual'
	functional: true

	# Get the index of the block this may be in
	inject: blockIndex: default: undefined

	props:

		# Support simple strings, arrays of objects, or an object that may be from
		# graphql or not
		image: Object | Array | String

		# The name of a product, also identifies this as a product image
		product: String | Boolean

		# Size related props
		aspect: Number
		natural: Boolean
		noUpscale: Boolean
		width: Number | String
		height: Number | String
		objectFit: String

		# Lazyload prop
		lazy: Boolean

		# Clear placeholder color, like for logos
		noPlaceholder: Boolean

		# Passthroughs
		sizes: String
		alt: String

	render: (create, { props, injections, data, children }) ->

		# If no image but an aspect, still create the Visual instance which
		# becomes a placeholder space
		unless (image = getAssetObject props.image) and image.src
			return create Visual, {
				...data
				props: aspect: if props.product then PRODUCT_ASPECT else props.aspect
			}, children

		# Auto replace _small suffix, the default if you don't specify size param
		# to Shopify's img_url
		image.src = image.src.replace /_small(\.(png|jpe?g|gif|svg))/, '$1'

		# Decide if there is a placeholder color
		placeholderColor = if props.noPlaceholder
		then null else defaultPlaceholderColor

		# Figure out the aspect ratio
		aspect = switch
			when props.product then PRODUCT_ASPECT
			when props.aspect then props.aspect
			when image.width && image.height then image.width / image.height

		# Get width and height from image prop if set to natural, otherwise use
		# values directly from props
		{ width, height } = if props.natural then image else props

		# Apply a max-width if no upscale is set
		maxWidth = if props.noUpscale then image.width

		# Helpers for automatically creating sizes
		sizes = if props.sizes then sizesHelpers props.sizes
		else if width then "#{parseInt(width)}px"

		# Make the alt if a product
		# Updated to render a blank alt text unless alt text is explicityly passed.
		# https://app.asana.com/0/1206074960267175/1206074971165072/f
		alt = if props.alt then props.alt else ""
		# else if props.product then "#{props.product} packaging"

		# If a product, use contain
		objectFit = if props.objectFit then props.objectFit
		else if props.product then 'contain'

		# Instantiate a Visual instance
		create Visual, {
			...data
			props:
				image: cropUrl image.src, width
				srcset: makeSrcset image.src, maxWidth or width
				aspect: aspect
				width: width
				height: height
				maxWidth: maxWidth
				lazyload: props.lazy ? false
				transition: unless props.lazy then ''
				placeholderColor: placeholderColor
				alt: alt
				sizes: sizes
				objectFit: objectFit
		}, children

# Shopify images in an array, so get the first item in the list
getAssetObject = (image) -> switch

	# If no value, then do nothing
	when !image then null

	# If it's a simple string, make an object out of it
	when typeof image == 'string' then { src: image }

	# If image is an array, return the first item
	when image.length
		image = image[0]
		if typeof image == 'string'
		then { src: image }
		else image

	# Else, return the image object
	else return image

# Make the unique image sizes by appending _{width}x right before the file
# extension. These are all of the sizes from craft-visual plus some very
# specific ones that correspond to the width of 4 column layout, like for better
# product grid images.
resizeWidths = [1440,1024,960,768,480,425,360,210]
makeSrcset = (image, maxWidth) ->

	# Don't generate sizes that are bigger than the maximum displayed size at
	# retina.
	sizes = unless maxWidth then resizeWidths
	else
		maxWidth = 2 * parseInt maxWidth
		resizeWidths.filter (size) -> size <= maxWidth

	# Make the string srcset list
	sizes.map((size) -> "#{cropUrl(image, size)} #{size}w").join ','

# Make a crop url for a Shopify image
cropUrl = (url, width = '', height = '') ->
	if width || height
	then url.replace /(\.(png|jpe?g|gif|svg))/, "_#{width}x#{height}$1"
	else url # If no width or height specified, return original image
