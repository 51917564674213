import { cookie, defer } from 'library/services/helpers'
import { getFavorites } from 'library/services/product-favoriting'

# Stores the state of favorites
export state = ->

	# Our favorites array that lives at @$store.state.favorites.products
	products: []

	# Track whether or not we've loaded up from cookies
	hydrated: false

export getters =

	# The number of favorited items
	count: (state) -> return state.products.length

	# Little DRY helper
	hasFavorites: (state, getters) -> getters.count > 0

export mutations =

	# Replace the whole favorites array with a new one
	replace: (state, favorites) -> state.products = favorites

	# Add a product handle if it's not already favorited
	add: (state, productHandle) ->
		return if state.products.includes productHandle
		state.products.push productHandle

	# Remove product if it's in the favorites array
	remove: (state, productHandle) ->
		return unless state.products.includes productHandle
		state.products.splice state.products.indexOf(productHandle), 1

	# Mark as hydrated
	isHydrated: (state) -> state.hydrated = true

export actions =

	# Toggles a product favorite state; loads if somehow unloaded
	toggle: ({ commit, state, dispatch, rootState }, productHandle ) ->
		await dispatch 'fetchUnlessHydrated'
		if productHandle in state.products
		then commit 'remove', productHandle
		else commit 'add', productHandle

	# Fetch favorites for the session.  Customer id can be empty
	fetch: ({ rootState, commit }) ->
		commit 'replace', await getFavorites rootState.customer.id

		# Wait a tick so that watchers on changes of favorites, like account-state,
		# can ignore the change when it's part of the original hydration.
		defer -> commit 'isHydrated'

	# Fetch if not already fetched
	fetchUnlessHydrated: ({ state, dispatch }) ->
		return if state.hydrated
		dispatch 'fetch'
