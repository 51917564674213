









export default

	# It warned me about just using 'header'
	name: 'item-header'

	props:
		item: Object

	# edge case where I wanted 2 columns of image-links
	# to line up exactly, so I'm rendering an empty space
	# if no header text exists
	computed:
		header: ->
			if @item.text?
				return @item.text
			else
				return '&nbsp;'

