












import inputMixin from 'library/mixins/input-mixin'

export default
	mixins: [inputMixin]

	props:
		fillColor:
			type: String
			default: 'red'

	computed:
		classes: -> @fillColor

