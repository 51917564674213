import * as $storefront from 'library/services/shopify/storefront'
import getBundlesData from 'library/queries/shopify/bundling/fetch.gql'
import { bundleRollupAll } from 'library/services/shopify/bundling/rollupProduct'
import { makeShopifyGid } from 'library/services/helpers/id'

export state = ->
	bundles: []
	hydrated: false

export getters =
	variantIsBundle: (state) -> (variantId) ->
		return state.bundles.some((bundle) ->
			bundle?.variants?[0]?.id == makeShopifyGid variantId, 'ProductVariant'
		)

	bundleFromVariantId: (state) -> (variantId) ->
		return state.bundles.find((bundle) ->
			bundle?.variants?[0]?.id == makeShopifyGid variantId, 'ProductVariant'
		)

export mutations =

	# Replace the whole favorites array with a new one
	replace: (state, bundles) -> state.bundles = bundles

	isHydrated: (state) -> state.hydrated = true

export actions =

	fetch: ({ rootState, commit }) ->
		commit 'replace', await getAllBundles()
		commit 'isHydrated'

# Fetch products that have a bundle tag and clean up the data
export getAllBundles = () ->

	# Fetch data
	{ bundles } = await $storefront.execute query: getBundlesData
	bundles.forEach (bundle) ->

		# There's potential for a bundle to not have completed metafields
		return unless bundle?.bundling?.bundleVariants
		bundle.bundling.bundleVariants = JSON.parse bundle?.bundling?.bundleVariants
		bundle.bundling.bundleVariants = bundle.bundling.bundleVariants.map (variant) -> {
			...variant
			id: variant.variantId
		}
		bundle.image = bundle.image?[0]

	bundles = await bundleRollupAll bundles, { $storefront }

	# Add extra data to make bundles easier to work with
	bundles.map (bundle) -> {
		...bundle
		sku: bundle?.variants?[0]?.sku
		bundleVariants: bundle?.bundling?.bundleVariants
			.filter (variant) -> variant?.variantId && variant?.count
		variant: image: bundle.image
	}

	.filter (bundle) ->
		# Remove bundles with no sku, which probably means we couldn't resolve the
		# JSON references?
		!!bundle?.sku &&

		# Remove bundles that did not have _productRollupComplete
		!!bundle?._productRollupComplete
