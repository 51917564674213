






























import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { makeLocaleSelectorOptions } from 'library/services/i18n/locales'

import ComboboxMixin from 'library/mixins/combobox'

export default
	mixins: [ ComboboxMixin ]

	data: ->
		# Determine the active country, like ignoring language
		activeCountryCode = @$i18n.defaultLocale.split('_')[1].toLowerCase()

		options = makeLocaleSelectorOptions()

		activeIdx = options.findIndex ({ countryCode }) =>
			countryCode == activeCountryCode

		return {
			activeIdx
			options
		}


	computed:


		# Return the active locale entry
		activeLocale: ->
			@options[@activeIdx]

		localeAriaLabel: ->
			if @open then "Locale selector open"
			else "Locale selector closed"

	watch:
		open: (open) ->
			if open then @$defer => disableBodyScroll @$refs.listbox
			else enableBodyScroll @$refs.listbox

		activeLocale:
			handler: (activeLocale) ->
				return unless activeLocale.url
				@$nextTick ->
					window.location.href = activeLocale.url
			immediate: false

	methods:
		getLocaleFlag: ({ countryCode }) -> "#{process.env.NUXT_APP_URL}/imgs/flags/#{countryCode}.svg"
