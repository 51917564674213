






























import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import keepFocus from 'library/mixins/keep-focus'
export default
	name: 'FlyoutSidebar'

	mixins: [ keepFocus ]

	props:
		noBackdrop: Boolean
		label: String
		initializer: null

	data: ->
		open: false # Will be triggered a tick after mount
		bodyOffset: null # Used to override the css top with the affect of banners

	mounted: ->

		# Listen for banner height changes
		@accomodateBanners()
		@accomodateBannersId = setInterval @accomodateBanners, 500

		# Wait a tick before listening for clicks outside so that it's not
		# triggered on the original open
		@$defer =>
			document.addEventListener 'click', @onPossibleClickOutside

			# Trigger appear transitions
			@open = true

			# Ensure the v-ifs have resolved...
			@$defer =>

				# Trap keyboard focus
				@initFocus()

				# Disable body scrolling
				disableBodyScroll @$refs.scroller

	# Cleanup
	beforeDestroy: ->
		clearTimeout @accomodateBannersId
		document.removeEventListener 'click', @onPossibleClickOutside
		clearAllBodyScrollLocks()
		@initializer?.focus()

	computed:

		# Dynamic styles
		styles: -> marginTop: @bodyOffset

	watch:

		# Fire events on open state changes
		open: -> if @open then @$emit 'open' else @$emit 'close'

		# If the route changes, automamtically close the sidebar
		'$route.fullPath': -> @close()

	methods:

		# If there is a margin-top on the body, assume it's because there is a
		# banner (like JustUno) trying to push the page down.  So push down by
		# an equal amount
		accomodateBanners: ->
			@bodyOffset = window.getComputedStyle(document.body)
			.getPropertyValue 'margin-top'

		# Trigger close unless the click occured in the tooltip
		onPossibleClickOutside: ({ target }) ->
			@close() unless target.closest '.flyout-sidebar-slot'

		# Trigger closing
		close: -> @open = false

		# After transition finishes, destroy the element
		remove: ->
			@$el.remove()
			@$destroy()
