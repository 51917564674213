###
Send GTM dataLayer events in response to cart actions
###
import {
	twoDecimals,
	getShopifyId,
	productNumberFromSku,
} from 'library/services/helpers'
import { GTM_EVENTS, BUNDLE_ATTRIBUTE_KEY } from 'library/constants'
import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'
import { removeBundleDataFromLineItem } from 'library/services/shopify/bundling/rollupLines'

# Main function that adds the subscribers
export addGtmCartSubscribers = (store) ->

	# Will store the line that is being acted upon. This is necessary because
	# when a line is deleted, the line item can't be used to look it up after
	# it was removed.
	capturedLine = null

	# Delegate relevant actions
	store.subscribeAction
		before: ({ type, payload }) -> switch type
			when 'cart/updateItem' then captureLine payload
		after: ({ type, payload }) -> switch type
			when 'cart/addItem' then fireAddItem payload
			when 'cart/addBundle' then fireAddBundle payload
			when 'cart/updateItem' then fireUpdateItem payload

	###
	Delegated methods
	###

	# Capture a line by it's id for the after methods
	captureLine = ({ lineId }) -> capturedLine = lookupLineById lineId

	# Item added to cart
	fireAddItem = ({ variantId, quantity, sellingPlanId, component }) ->
		fireLineEvent {
			...lookupVariant { variantId, sellingPlanId }
			event: GTM_EVENTS.ADD_TO_CART
			quantity
			isSubscription: !!sellingPlanId
			component
		}
		fireCartEvent()

	# Bundle added to cart
	fireAddBundle = ({ bundleSku, component, quantity }) ->
		fireLineEvent {
			...lookupBundle bundleSku
			event: GTM_EVENTS.ADD_TO_CART
			quantity
			isSubscription: false # Not supported yet
			component
		}
		fireCartEvent()

	# Item quantity updated
	fireUpdateItem = ({ lineId, quantity, component }) ->

		# Try and use the current line. However, if it is being deleted by the
		# quantity being set to 0, it won't exist.  In this case, use the captured
		# line.
		unless line = lookupLineById(lineId) || capturedLine
		then throw "Line couldn't be found #{lineId}"

		# Fire events
		fireLineEvent {
			...line.variant
			event: GTM_EVENTS.UPDATE_CART
			quantity
			isSubscription: !!line.sellingPlanAllocation
			component
		}
		fireCartEvent()

	###
	GTM Events
	###

	# Fire event about the line item event
	fireLineEvent = (evt) ->
		{ event, quantity, productNumber, price, sku, id, isSubscription,
			component, product, image } = evt

		# Make the URL from the handle. Not adding onlineStoreUrl to the GQL query
		# because it's always null on UAT & Dev
		productUrl = "#{process.env.SHOPIFY_URL}/products/#{product.handle}"

		clearEnhancedEcommerceObject()
		window?.dataLayer?.push {
			event
			sku
			productUid: sku # Legacy property
			productNumber
			isSubscription: isSubscription || false
			quantity
			price: twoDecimals price
			variantId: "#{getShopifyId(id)}"  # Algolia needs a string
			cartItems: store.getters['cart/lineItems'].map enhanceLineData
			userId: store.state.cart.userId
			userInfo: store.state.cart.userId # Legacy property
			component
			productHandle: product.handle
			productUrl

			# Should always exist, but didn't want to risk bricking site in short term
			# since this is all getting replaced
			productImageUrl: image?.src

			# Enhanced Ecommerce Object
			...getEnhancedEcommerceProps(evt)

			# Despite the names, these are expected to be cart wide
			'cartItem.qty': store.getters['cart/itemCount']
			'cartItem.itemtotal': twoDecimals store.getters['cart/subtotal']
		}

	# Fire event about the overall state of the cart
	fireCartEvent = ->
		clearEnhancedEcommerceObject()
		window?.dataLayer?.push {
			event: GTM_EVENTS.CART_DETAILS

			# Enhanced Ecommerce Object
			...getEnhancedEcommerceProps({event: GTM_EVENTS.CART_DETAILS})
		}

	###
	Helpers
	###

	# Clear the previous ecommerce object. https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
	clearEnhancedEcommerceObject = -> window?.dataLayer?.push({ ecommerce: null })

	# Get variant data from the line items by it's simple Shopify id
	lookupVariant = ({ variantId, sellingPlanId }) ->
		lineItem = store.state.cart.lineItems.find (lineItem) ->

			# Skip bundles
			return if lineItem.attributes?.some ({ key }) ->
				key == BUNDLE_ATTRIBUTE_KEY

			# Match on variant id and selling plan id
			lineSellingPlanId = lineItem.sellingPlanAllocation?.sellingPlan?.id
			getShopifyId(lineItem.variant.id) == getShopifyId(variantId) &&
			getShopifyId(lineSellingPlanId) == getShopifyId(sellingPlanId)

		# Return enhanced variant object
		unless lineItem then throw "Variant not found: #{variantId}"
		return enhanceLineData(lineItem).variant

	# Lookup a bundle "variant" using the lineItems getter to access rolled up
	# bundle lines
	lookupBundle = (sku) ->
		lineItem = store.getters['cart/lineItems'].find (lineItem) ->
			return unless lineItem?.bundleVariants?.length # Skip non-bundles
			return lineItem.variant.sku == sku
		unless lineItem then throw "Bundle not found: #{sku}"
		return enhanceLineData(lineItem).variant

	# Get variant data from the line items by a line item id
	lookupLineById = (lineItemId) ->
		lineItem = store.getters['cart/lineItems'].find (lineItem) ->
			getShopifyId(lineItem.id) == getShopifyId(lineItemId)
		unless lineItem then return null
		return enhanceLineData lineItem

	# Add extra data to the cart line objects
	enhanceLineData = (lineItem) ->
		variant = lineItem.variant

		# Make the Clif product number
		productNumber = productNumberFromSku variant.sku

		# If a subscription, use subscription pricing
		price = if lineItem.sellingPlanAllocation # Is a subscription
		then twoDecimals applySubscriptionDiscount variant.price
		else twoDecimals variant.price

		# Make the product URL
		url = "#{process.env.SHOPIFY_URL}/products/#{variant.product.handle}"

		# Return the line with adjusted properties
		{
			...removeBundleDataFromLineItem(lineItem)
			variant: {
				...variant
				productNumber
				price
				product: {
					...variant.product
					url
				}
			}
		}


	# Get Enhanced Ecommerce properties based on a GA event name.
	getEnhancedEcommerceProps = (evt) -> switch evt.event

		# Adding a Product to a Shopping Cart. https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#add
		when GTM_EVENTS.ADD_TO_CART then ({
			ecommerce:
				add:
					products: [{
						id: evt.sku
						name: "#{evt.product.title} - #{evt.title}"
						price: evt.price
						brand: evt.product.productType
						quantity: evt.quantity
					}]
			})

		# Updating cart Details
		when GTM_EVENTS.CART_DETAILS then ({
			ecommerce:
				cartDetails:
					item_count: store.getters['cart/itemCount']
					items: store.getters['cart/lineItems']
					original_total_price: twoDecimals store.getters['cart/subtotal']
					total_discount: "0"
					total_price: twoDecimals store.getters['cart/total']
				user: id: store.state.cart.userId
			})

		when GTM_EVENTS.UPDATE_CART

			# Removing a product from a Shopping Cart. https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#remove
			if evt.quantity == 0 then return ({
				ecommerce:
					remove:
						products: [{
							id: evt.sku
							name: "#{evt.product.title} - #{evt.title}"
							price: twoDecimals evt.price
							brand: evt.product.productType
							quantity: 1
						}]
				})

		else ({})
