###
Sync the current cart count with a cookie and only hydrate the cart when it's
viewed or updated
###
import { cookie, wait } from 'library/services/helpers'
import { CART_COUNT_KEY } from 'library/constants'
export lazyHydrateCart = (store) ->

	# Keep the cart count cookie up to date
	store.watch ((state, getters) -> getters['cart/itemCount'])
	, ((count) -> cookie.set CART_COUNT_KEY, count)

	# Hydrate the cart after a short delay. This should take it off the main
	# thread and give more important stuff a chance to load. This was done so
	# that page state that depends on knowing the cart state, specifically strike
	# through pricing from expert voice users, will be populated without the
	# customer opening the cart
	wait 100, -> store.dispatch 'cart/fetchUnlessHydrated'
