






































import CustomerWelcome from '../account/customer-welcome'
import FlyoutSidebar from './flyout-sidebar'
import IconNavigationBtn from 'library/components/account/icon-navigation-btn'
import NextSubscriptionShipment from '../account/next-subscription-shipment'
export default

	components: {
		CustomerWelcome
		FlyoutSidebar
		IconNavigationBtn
		NextSubscriptionShipment
	}

	# Fetch navigation data
	mounted: ->
		@$store.dispatch 'customer/navigation/fetchUnlessHydrating'
		@$gtm.push
			event: @$GTM_EVENTS.OPEN_SIDEBAR
			component: @$GTM_COMPONENTS.ACCOUNT_FLYOUT

	computed:

		# Vuex accessors
		links: -> @$store.getters['customer/navigation/links']
