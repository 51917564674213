



































































import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'
import addDays from 'date-fns/addDays'
import startOfDay from 'date-fns/startOfDay'
import format from 'date-fns/format'
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
import { ordergrooveApi } from 'library/services/ordergroove/helpers'

import Modal from 'library/components/global/modals/modal'
import SubscriptionRadio from './subscription-radio'
import SubscriptionFrequency from './subscription-frequency'

export default
	name: 'addToDeliveryModal'

	inject:
		onQuickShopModal: default: false

	components: {
		Modal
		SubscriptionRadio
		SubscriptionFrequency
	}

	props:
		handle: String
		variantId: String | Number
		sellingPlanAllocations: Array
		initializer: null

		onceText: String
		subscribeText: String

		onSuccess:
			type: Function
			default: () -> return


	data: ->
		subscriptionType: 'addOnce'
		selectedSellingPlan: null
		added: false
		adding: false

	computed:
		label: -> @handle + "_subscription-delivery"

		_onceText: -> @onceText || "Purchase one time"

		_subscribeText: -> @subscribeText || "Add to your <b>#{@nextShipmentDate}</b> delivery and receive #{SUBSCRIPTION_DISCOUNT_PERCENTAGE}% off"

		nextShipmentDate: -> format (startOfDay addDays new Date(@$store.state.customer.navigation.unsentSubscriptions?[0]?.shipmentDate), 1), "MMMM dd"

		isBundleVariant: -> @$store.getters['bundles/variantIsBundle'](@variantId)

		bundleVariants: ->
			return [] unless @isBundleVariant
			@$store.getters['bundles/bundleFromVariantId'](@variantId).bundleVariants

	methods:
		closeModal: -> @$refs.addToDeliveryModal.close()

		handleSubmit: ->
			if @subscriptionType == 'subscribe'
				await @addToOrder({
						every: @selectedSellingPlan.every
						every_period: @selectedSellingPlan.every_period
					})
			else
				await @addToOrder()


		addToOrder: (payload) ->
			@adding = true
			if @isBundleVariant
				await Promise.all(@bundleVariants.map((variant) =>
					console.log variant
					return ordergrooveApi(
						'addProductToNextOrder',
						@$store.getters['customer/navigation/accessToken'],
						{
							variantId: @$getShopifyId(variant.id),
							quantity: variant.count || 1
							...(if (!!payload?.every && !!payload?.every_period) then payload else {})
						}
					)
				))
			else
				await ordergrooveApi(
					'addProductToNextOrder',
					@$store.getters['customer/navigation/accessToken'],
					{
						variantId: @$getShopifyId(@variantId)
						...(if (!!payload?.every && !!payload?.every_period) then payload else {})
					}
				)
			# Fire 'Add to Subscription' GTM event
			@$gtm.push
				event: GTM_EVENTS.ADD_TO_SUBSCRIPTION
				component: if @onQuickShopModal then GTM_COMPONENTS.QUICK_SHOP_MODAL else GTM_COMPONENTS.PRODUCT_CARD
				variantId: @$getShopifyId @variantId

			@adding = false
			@added = true
			@onSuccess()
