###
Hydrate customer info using accessToken that was set in a cookie
###

# Deps
import { fireEventOnCustomerMutation } from 'library/services/gtm/identify-customer'
import { CUSTOMER_ACCESS_TOKEN } from 'library/constants'
import { defer } from 'library/services/helpers'

export hydrateCustomer = (store) ->

	# Fire DataLayer events when customer data changes
	fireEventOnCustomerMutation store

	# If on Shopify stack and no liquid session, delete Storefront session.
	# However, if on the recaptcha challenge page, don't logout, since we're
	# still mid-login and the CUSTOMER_SESSION will be empty.
	lacksShopifySession = !process.env.IS_NUXT and !window?.CUSTOMER_SESSION
	isNotMidLogin = window.location.pathname != '/challenge'
	if lacksShopifySession and isNotMidLogin
		await store.dispatch 'customer/logoutFromStorage'

	# If the request if from OG's "login on behalf of" feature, force the
	# storefront session to be the same as liquid one, which has been created
	# using OG's multipass. Send them on to OG subscription page
	hasShopifySession = !process.env.IS_NUXT and window?.CUSTOMER_SESSION
	isImpersonating = window?.location.search.includes 'login_on_behalf_of'
	if hasShopifySession and isImpersonating
		await store.dispatch 'customer/loginFromShopifySession'
		return window.location = '/account?view=subscriptions'

	# Login customer from cookies and create access token
	await store.dispatch 'customer/loginFromStorage'

	# If the user has a liquid session but no access token, then log them in
	# by getting an accessToken via our Netlify function that uses multipass to
	# create the accessToken
	lacksStorefrontSession = store.getters['customer/isGuest']
	if hasShopifySession and lacksStorefrontSession
		try await store.dispatch 'customer/loginFromShopifySession'

		# If there was an error, log them out of their Shopify session.
		catch e
			console.error e
			window.location = '/account/logout'

	# If the customer was signed in, fetch their navigation data automatically
	# so it's ready for use in cart (where we want the subscription count). Using
	# defer so a separate thread is used.
	defer -> store.dispatch 'customer/navigation/fetchUnlessHydrating'
