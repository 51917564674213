import { loadScript } from 'library/services/helpers'
import { ONETRUST_CATEGORIES } from 'library/services/onetrust/onetrust-constants'

export initRecaptchaListener = () ->

	# Self removed funcs
	registerInteractions = () ->
		url = 'https://www.google.com/recaptcha/api.js?render=' + process.env.RECAPTCHA_SITE_KEY
		loadScript url, consent: ONETRUST_CATEGORIES.NECESSARY
		document.body.removeEventListener 'click', registerInteractions
		document.body.removeEventListener 'keyup', registerInteractions

	# Listen for interactions and update the store
	document.body.addEventListener 'click', registerInteractions
	document.body.addEventListener 'keyup', registerInteractions
