# Format a string or number like money
export formatMoney = (val) ->
	locale = navigator?.language || 'en-us'
	Number(flattenMoney(val)).toLocaleString locale,
		minimumFractionDigits: 2
		maximumFractionDigits: 2
		style: 'currency'
		currency: 'USD'

# Flatten money values from Shopify MoneyV2
export flattenMoney = (val) ->
	if val?.amount? then val = val.amount else val

# Add two decimal places
export twoDecimals = (val) ->
	return unless val? # Return void if null-ish
	Number(flattenMoney(val)).toFixed(2)

# Pad a number with 0s
export padNum = (num, size = 2) ->
	String(Math.round(num)).padStart(size, '0')
