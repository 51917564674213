###
Trying to harden Axios requests during SSG by retrying and re-using connections.
###

# Deps
axios = require 'axios'
axiosRetry = require 'axios-retry'
Agent = require 'agentkeepalive'

# Make client that uses the keep alive agent
# https://gist.github.com/ccnokes/94576dc38225936a3ca892b989c9d0c6
client = axios.create
	httpAgent: new Agent
	httpsAgent: new Agent.HttpsAgent

# Enable retrying requests
axiosRetry client,
	retries: 3
	retryDelay: axiosRetry.exponentialDelay

# Export my customized axios client as CJS for use by node stuff like
# routes.coffee
module.exports = client
