import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5f0142d3 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _0d62f55c = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _c8e4ee72 = () => interopDefault(import('../pages/test-combo.vue' /* webpackChunkName: "pages/test-combo" */))
const _5a025192 = () => interopDefault(import('../pages/activities/_activity.coffee' /* webpackChunkName: "pages/activities/_activity" */))
const _e54c50ca = () => interopDefault(import('../pages/athletes/_athlete.coffee' /* webpackChunkName: "pages/athletes/_athlete" */))
const _72a8523f = () => interopDefault(import('../pages/collections/_collection.vue' /* webpackChunkName: "pages/collections/_collection" */))
const _758aff25 = () => interopDefault(import('../pages/events/_event.coffee' /* webpackChunkName: "pages/events/_event" */))
const _7f73ec85 = () => interopDefault(import('../pages/faqs/_faq.vue' /* webpackChunkName: "pages/faqs/_faq" */))
const _97c496f4 = () => interopDefault(import('../pages/press-releases/_article.coffee' /* webpackChunkName: "pages/press-releases/_article" */))
const _e7d3cc74 = () => interopDefault(import('../pages/products/_flavor.vue' /* webpackChunkName: "pages/products/_flavor" */))
const _2d9c456c = () => interopDefault(import('../pages/stories/_story.coffee' /* webpackChunkName: "pages/stories/_story" */))
const _becc34d8 = () => interopDefault(import('../pages/_tower.coffee' /* webpackChunkName: "pages/_tower" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___en_AU"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___de_AT"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___de_BE"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___en_CA"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___fr_CA"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___fr_FR"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___de_DE"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___en_IE"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___it_IT"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___nl_NL"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___en_NZ"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___pt_PT"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___es_ES"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___se_SE"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___de_CH"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___en_GB"
  }, {
    path: "/favorites",
    component: _5f0142d3,
    name: "favorites___en_US"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___en_AU"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___de_AT"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___de_BE"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___en_CA"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___fr_CA"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___fr_FR"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___de_DE"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___en_IE"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___it_IT"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___nl_NL"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___en_NZ"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___pt_PT"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___es_ES"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___se_SE"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___de_CH"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___en_GB"
  }, {
    path: "/search",
    component: _0d62f55c,
    name: "search___en_US"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___en_AU"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___de_AT"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___de_BE"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___en_CA"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___fr_CA"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___fr_FR"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___de_DE"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___en_IE"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___it_IT"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___nl_NL"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___en_NZ"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___pt_PT"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___es_ES"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___se_SE"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___de_CH"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___en_GB"
  }, {
    path: "/test-combo",
    component: _c8e4ee72,
    name: "test-combo___en_US"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___en_AU"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___de_AT"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___de_BE"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___en_CA"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___fr_CA"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___fr_FR"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___de_DE"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___en_IE"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___it_IT"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___nl_NL"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___en_NZ"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___pt_PT"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___es_ES"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___se_SE"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___de_CH"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___en_GB"
  }, {
    path: "/activities/:activity",
    component: _5a025192,
    name: "activities-activity___en_US"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___en_AU"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___de_AT"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___de_BE"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___en_CA"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___fr_CA"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___fr_FR"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___de_DE"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___en_IE"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___it_IT"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___nl_NL"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___en_NZ"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___pt_PT"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___es_ES"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___se_SE"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___de_CH"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___en_GB"
  }, {
    path: "/athletes/:athlete",
    component: _e54c50ca,
    name: "athletes-athlete___en_US"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___en_AU"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___de_AT"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___de_BE"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___en_CA"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___fr_CA"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___fr_FR"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___de_DE"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___en_IE"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___it_IT"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___nl_NL"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___en_NZ"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___pt_PT"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___es_ES"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___se_SE"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___de_CH"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___en_GB"
  }, {
    path: "/collections/:collection?",
    component: _72a8523f,
    name: "collections-collection___en_US"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___en_AU"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___de_AT"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___de_BE"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___en_CA"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___fr_CA"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___fr_FR"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___de_DE"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___en_IE"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___it_IT"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___nl_NL"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___en_NZ"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___pt_PT"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___es_ES"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___se_SE"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___de_CH"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___en_GB"
  }, {
    path: "/events/:event",
    component: _758aff25,
    name: "events-event___en_US"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___en_AU"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___de_AT"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___de_BE"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___en_CA"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___fr_CA"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___fr_FR"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___de_DE"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___en_IE"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___it_IT"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___nl_NL"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___en_NZ"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___pt_PT"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___es_ES"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___se_SE"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___de_CH"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___en_GB"
  }, {
    path: "/faqs/:faq",
    component: _7f73ec85,
    name: "faqs-faq___en_US"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___en_AU"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___de_AT"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___de_BE"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___en_CA"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___fr_CA"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___fr_FR"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___de_DE"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___en_IE"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___it_IT"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___nl_NL"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___en_NZ"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___pt_PT"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___es_ES"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___se_SE"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___de_CH"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___en_GB"
  }, {
    path: "/press-releases/:article",
    component: _97c496f4,
    name: "press-releases-article___en_US"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___en_AU"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___de_AT"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___de_BE"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___en_CA"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___fr_CA"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___fr_FR"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___de_DE"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___en_IE"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___it_IT"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___nl_NL"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___en_NZ"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___pt_PT"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___es_ES"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___se_SE"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___de_CH"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___en_GB"
  }, {
    path: "/products/:flavor",
    component: _e7d3cc74,
    name: "products-flavor___en_US"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___en_AU"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___de_AT"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___de_BE"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___en_CA"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___fr_CA"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___fr_FR"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___de_DE"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___en_IE"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___it_IT"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___nl_NL"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___en_NZ"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___pt_PT"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___es_ES"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___se_SE"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___de_CH"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___en_GB"
  }, {
    path: "/stories/:story",
    component: _2d9c456c,
    name: "stories-story___en_US"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___en_AU"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___de_AT"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___de_BE"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___en_CA"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___fr_CA"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___fr_FR"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___de_DE"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___en_IE"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___it_IT"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___nl_NL"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___en_NZ"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___pt_PT"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___es_ES"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___se_SE"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___de_CH"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___en_GB"
  }, {
    path: "/:tower*",
    component: _becc34d8,
    name: "tower___en_US"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
