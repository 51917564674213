
























import NumberInput from 'library/components/global/number-input'
import debounce from 'lodash/debounce'

export default

	components: { NumberInput }

	props:
		variant: Object
		component: String # For GTM reporting

	data: ->
		loading: false
		quantity: 0

	computed:

		title: -> @variant.title

		# shorthand to get the variant gid
		id: -> @$makeShopifyGid @variant.id

		# how many of these items are currently in the cart. this powers the
		# decrease function
		currentCartLine: ->
			@$store.getters['cart/lineItems'].find (item) =>
				@$getShopifyId(item.variant.id) == @$getShopifyId(@variant.id)

		currentCartLineQty: -> @currentCartLine?.quantity || 0

		# disable the button if
		# there's nothing to decrease
		decreaseClasses: -> [
			'disabled' if !@currentCartLineQty
		]

		# visual classes for UI
		loadingClasses: -> 'loading' if @loading

	mounted: -> @quantity = @currentCartLineQty

	methods:

		addOneTime: ->
			@mutate 'increased', 1

		update: debounce (qty) ->
			delta = @currentCartLineQty - qty
			return unless delta
			if delta > 0
				type = 'decreased'
			else
				type = 'increased'
			absDelta = Math.abs delta
			await @mutate type, absDelta
		, 200

		mutate: (type, qty) ->

			@loading = true
			# add
			if type == 'increased'
				await @$store.dispatch 'cart/addItem', {
					variantId: @$getShopifyId @variant.id
					quantity: qty
					@component
				}

			# remove
			else if type == 'decreased'
				cartQty = @currentCartLine?.quantity

				if qty > cartQty then modifiedAmount = 0
				else modifiedAmount = cartQty - qty

				await @$store.dispatch 'cart/updateItem', {
					lineId: @currentCartLine?.id
					quantity: modifiedAmount
					@component
				}

			delta = qty
			if qty > cartQty then delta = cartQty

			payload = {
				type: type
				delta: delta
			}

			@$emit 'modified', payload
			@loading = false

	watch:
		currentCartLineQty: (qty) ->
			if @quantity != qty
				@quantity = qty

		quantity: (qty) ->
			@update(qty)


