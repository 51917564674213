# Choose the endpoint to submit CDIL forms to
export getSfmcOrigin = ->
	hostname = window?.location?.hostname
	switch

		# Use Netlify function localhost if specified
		when process.env.NETLIFY_FUNCTIONS_URL
			return process.env.NETLIFY_FUNCTIONS_URL

		# On the prod shop subdomain use the absolute prod URL
		when hostname == 'shop.clifbar.com'
			return 'https://www.clifbar.com'

		# On the UAT shop subdomain use the absolute UAT URL
		when hostname == 'uat-shop.clifbar.com'
			return 'https://uat-www.clifbar.com'

		# On Nuxt sites, use relative URL
		else
			return ''
