###
Build a client for querying the Shopify Storefront API
###
import { makeStorefrontClient } from '@cloak-app/shopify/factories'
import productCardFragment from 'library/queries/shopify/product-card.gql'
import getCollectionProducts from 'library/queries/shopify/collection-products.gql'
import { bundleRollupAll } from 'library/services/shopify/bundling/rollupProduct'
import {
	onlyPublishedProducts,
	isPublishedProduct,
} from 'library/services/helpers/product'

# Use @cloak-app/shopify to make the base client
export default $storefront = makeStorefrontClient
	url: process.env.SHOPIFY_URL
	token: process.env.SHOPIFY_STOREFRONT_TOKEN

# Export $storefront methods for backwards compatability
export execute = $storefront.execute

# Helper method to get a collection and it's products
export getCollection = (handle, limit = 250) ->

	# To support rendering /collections on nuxt
	handle = handle || 'all'

	# Get the data
	data = await execute
		query: getCollectionProducts
		variables: { handle, limit }

	# Make easy to interact with objects
	collection = data.collectionByHandle
	products = onlyPublishedProducts collection.products

	products = await bundleRollupAll products, { $storefront }

	# Return destructure-able payload
	return { collection, products }

# Helper to look up product card data from their handles by querying the
# productByHandle method multiple times
export getProductCards = (handles) ->
	return [] unless handles?.length

	# Make the list of queries
	productQueries = handles.map (handle, index) -> """
		product_#{index}: productByHandle(handle:"#{handle}") {
			...productCard
		}
	"""

	# Assemble full query
	query = """
		query getProductCards {
			#{productQueries.join("\n")}
		}
		#{productCardFragment}
	"""

	# Execute the query and return only valid products
	return onlyPublishedProducts Object.values await execute { query }

# Re-export helpers for backwards compatability. These were moved out of this
# file to resolve circular import issues
export { onlyPublishedProducts, isPublishedProduct }
