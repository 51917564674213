

























import LocaleLanguageSwitcher from 'library/components/layout/header/locale-language-switcher'
import SiteLogo from 'library/components/layout/site-logo'
import SmartNavLink from './smart-nav-link'
import { makeLanguageSwitcherOptions } from 'library/services/i18n/locales'

export default

	data: ->
		choices: []
		transition: 'slide-in-from-right'

	components: {
		LocaleLanguageSwitcher
		SmartNavLink
		SiteLogo
	}

	computed:

		choicesKey: -> @choices.join ''

		currentPage: ->
			page = @navigationData
			for index in @choices
				page = page.children?[index] || page[index]
			return page

		title: -> @currentPage.title || null

		links: ->
			if @choices.length == 0 then return @navigationData
			@currentPage.children

		navigationData: -> @$store.state.globals.primaryMobileMenu.children

		# Helper to find if there are multiple langauges for a locale, and is not domestic
		hasManyLanguages: ->
			return unless !@$cartEnabled
			languages = makeLanguageSwitcherOptions()
			languages.length > 0

	watch:

		'choices.length': (now, old) ->
			@transition = if now > old then 'slide-in-from-right' else 'slide-out-from-left'

	methods:

		back: -> @choices.pop()

		forward: (choice) ->
			@choices.push choice
			@$emit 'resolvedlinks'

