import SmartLink from 'vue-routing-anchor-parser/smart-link'
import URL from 'url-parse'
import { isShopifyUrl, makeShopifyUrl } from 'library/services/helpers'

# Make component by destructuring vue-routing-anchor-parser version
export default {

	# Inherit defaults
	...SmartLink

	# Destructure the props and data we care about
	render: (create, context) ->

		# If a Shopify URL, make an anchor with that domain
		if isShopifyUrl to = context.props.to
		then makeAnchor create, context, makeShopifyUrl to

		# Else do default smart link logic
		else SmartLink.render create, context

}

# Create a normal anchor while expecting a `to` prop
export makeAnchor = (create, context, url = null) ->
	{ data, props: { to }, children } = context
	create 'a', {
		...data
		attrs: {
			...data.attrs
			href: url || to
		}
	}, children
