###
Creates prevents body scroll lock when toggled.  Open may be a prop or data
so it's not defined here.
###
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
export default

	# Cleanup if destroyed
	destroyed: -> enableBodyScroll @$el

	watch:

		# When open, enable body scroll lock
		open:
			immediate: true
			handler: -> @$nextTick ->
				return unless @$el # Won't exist during SSG

				# Bind body-scroll to exact scroll target, not whole modal
				# Otherwise, just bind to @$el on non-modal cases
				# https://github.com/willmcpo/body-scroll-lock/tree/master/examples/modal
				# https://github.com/willmcpo/body-scroll-lock/issues/200
				target = @$el.querySelector('.scroll-target') || @$el
				html= document.querySelector 'html'
				if @open
					html.style.height = '100vh'
					disableBodyScroll target
				else
					html.style.height = ''

					enableBodyScroll target

	methods:

		# Remove the modal, helper method
		close: -> @open = false
