import { makeImgixUrl } from './url'
import { defer } from './function'

# Helper for making meta tags
export metaTag = (key, val, keyAttribute = null) ->

	# Return nothing if no val
	return unless val
	return if Array.isArray(val) and !val.length

	# Make the key attribute
	unless @keyAttribute
		keyAttribute = if key.match /^og:/ then 'property' else 'name'

	# Get image from Craft's array
	if key == 'og:image' and Array.isArray val
	then val = makeImgixUrl val[0], 1200

	# Strip HTML by default, so WYSIWYG values can be passed in
	# https://stackoverflow.com/a/5002161/59160
	return unless val = val.replace /<\/?[^>]+(>|$)/g, ''

	# Return object
	hid: key
	"#{keyAttribute}": key
	content: val

# Add JS initted class to document for use by in-viewport transitions
export addInittedClass = ->
	defer -> document.documentElement.classList.add 'js-initted'
