
























import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
export default

	computed:

		# Vuex accessors
		hydrated: -> @$store.state.customer.navigation.hydrated
		nextShipmentDate: ->
			@$store.state.customer.navigation.unsentSubscriptions?[0]?.shipmentDate

		# Get the next date
		delayUntilNextShipment: ->
			return unless @nextShipmentDate
			daysDiff = differenceInCalendarDays @nextShipmentDate, new Date
			switch
				when daysDiff == 0 then 'today'
				when daysDiff == 1 then "tomorrow"
				when daysDiff > 1 then "in #{daysDiff} days"

