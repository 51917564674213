###
Shared code used to render line items in carts
###
import NumberInput from 'library/components/global/number-input'
import Price from 'library/components/global/price'
import debounce from 'lodash/debounce'
import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'
export default

	# A line item, either subscription or storefront API lineItem
	props: item: Object

	# Common components
	components: {
		NumberInput
		Price
	}

	data: ->
		component: null # No-op for GTM events
		quantity: @item.quantity
		loading: false
		currentSellingPlanAllocation: @item?.sellingPlanAllocation

	computed:

		# Make the product title from the product type and product title
		title: -> "#{@productType.toUpperCase()} #{@item?.title || @item?.variant?.product?.title}"

		# Get the product type
		productType: -> @item.variant.product.productType

		# Make the URL progromatically
		url: -> '/products/' + @item.variant.product.handle

		# Make a clean object to prevent errors about mutating object
		image: -> { ...@item.variant.image }

		# Do selling plans exists for the variant
		hasSellingPlans: -> @sellingPlanAllocations?.length

		# Alias selling plan
		sellingPlanAllocations: -> @item?.variant?.sellingPlanAllocations
		sellingPlan: -> @currentSellingPlanAllocation?.sellingPlan

		# Test if this line item is for a subscription
		isSubscription: -> !!@currentSellingPlanAllocation

		# Helper for getting the current price
		price: -> parseFloat @item?.variant?.price.amount
		lineSubtotalPrice: -> parseFloat @item?.cost.subtotalAmount.amount
		lineSubtotalUnitPrice: -> @lineSubtotalPrice / @item.quantity
		lineTotalPrice: -> parseFloat @item?.cost.totalAmount.amount
		lineTotalUnitPrice: -> @lineTotalPrice / @item.quantity

		# Get the discounted price based on the chosen selling plan
		subscriptionPrice: ->
			return unless @hasSellingPlans
			applySubscriptionDiscount @price

		# Calculate the discount percentage based on the chosen selling plan
		subscriptionDiscount: ->
			return unless @subscriptionPrice
			Math.round(100 * (1 - @subscriptionPrice / @price)) + '%'

		# Label for subscribe checkbox
		subscribeAndSave: ->
			if @subscriptionDiscount != "0%"
				# Non-breaking space to force "Save X%" on the same line
				"Subscribe & Save&nbsp#{@subscriptionDiscount}"
			else "Subscribe"

		# Test whether this is a gift
		isGift: -> @item.variant.product.tags?.includes 'no_purchase'

		# Get the update action to use
		updateAction: ->
			if @isSubscription then 'cart/updateSubscription' else 'cart/updateItem'

	methods:

		# Remove item from cart. Not touching the quantity directly so we don't
		# trigger the delayed debounce.
		removeItem: -> @setQuantity 0

		# Set the quantity of the line item
		setQuantity: (quantity) ->
			@loading = true
			await @$store.dispatch @updateAction,
				lineId: @item.id
				quantity: quantity
				component: @component
			@loading = false

		# Update the cart on a debounce
		setQuantityDebounced: debounce (quantity) ->
			@setQuantity quantity
		, 250

		updateSellingPlan: (allocation) ->
			@loading = true
			if !allocation
			then await @$store.dispatch 'cart/removeSellingPlan',
				lineId: @item.id
				component: @component
			else await @$store.dispatch 'cart/updateSellingPlan',
				lineId: @item.id,
				sellingPlanId: allocation?.sellingPlan?.id
				component: @component
			@loading = false

	watch:

		# Update the cart when the quantity
		quantity: -> @setQuantityDebounced @quantity

		# Update the selling plan if the user has changed their selling plan
		currentSellingPlanAllocation:
			handler: (allocation, prevAllocation) ->
				return if allocation?.sellingPlan?.id == prevAllocation?.sellingPlan?.id
				await @updateSellingPlan allocation
			deep: true

		# Sync the cart's selling plan state to the state of this component
		'item.sellingPlanAllocation':
			handler: (allocation) ->
				return if allocation?.sellingPlan?.id == @currentSellingPlanAllocation?.sellingPlan?.id
				@currentSellingPlanAllocation = allocation
			deep: true

		# Our quantity can get updated by other factors like unchecking the
		# subscription opt in
		'item.quantity': (quantity, prevQuantity) ->
			return if quantity == prevQuantity
			return if quantity == @quantity
			@quantity = quantity
