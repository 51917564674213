











import DefaultLayout from './default'
export default
	components: { DefaultLayout }

