





















export default

	props:
		item: Object

	computed:
		hasDropdownMenu: -> @item.headerMenuColumns.length

	methods:

		openDropdown: ->
			if @hasDropdownMenu then @$emit 'open', @item

		closeDropdown: ->
			if @hasDropdownMenu then @$emit 'close', @item

		# Specific active class for store routes on shopify
		storeActive: (path) ->
			if path?.includes 'collections'
				if window?.location.href.includes(path) or window?.location.href.includes ('products')
					return true


