








































































import AccountState from '../account-state/account-state'
import AffixingHeader from 'library/components/layout/header/affixing-header'
import CartIcon from 'library/components/layout/cart-icon'
import FavoritesNavIcon from 'library/components/global/favorites/nav-icon'
import LocaleLanguageSwitcher from 'library/components/layout/header/locale-language-switcher'
import NavigationDropdown from './navigation-dropdown'
import NavigationItem from './navigation-item'
import SearchDropdown from './search-dropdown'
import SearchTrigger from './search-trigger'
import SiteLogo from 'library/components/layout/site-logo'
import dimensions from 'library/styles/vars/dimensions'
import { makeLanguageSwitcherOptions } from 'library/services/i18n/locales'

export default

	components: {
		AccountState
		AffixingHeader
		CartIcon
		FavoritesNavIcon
		LocaleLanguageSwitcher
		NavigationDropdown
		NavigationItem
		SearchDropdown
		SearchTrigger
		SiteLogo
	}

	data: ->
		height: parseInt dimensions['header-h']
		selectedNav: null
		mouseOverDropdown: false

	computed:

		navigationMenus: ->
			@$store.state.globals.desktopNavigation?.children?.filter(({__typename}) -> __typename == "navigation_desktopHeaderMenu_Entry") || []

		navigationButtons: ->
			@$store.state.globals.desktopNavigation?.children?.filter(({__typename}) -> __typename == "navigation_desktopHeaderButton_Entry") || []

		open: -> !!@selectedNav

		searchAriaLabel: ->
			if @open then "Search is open"
			else "Search is closed"

		# Helper to get the id property of selectedNav, if it's an object
		selectedNavId: -> @selectedNav?.id

		# Helper to find if there are multiple langauges for a locale, and is not domestic
		hasManyLanguages: ->
			return unless !@$cartEnabled
			languages = makeLanguageSwitcherOptions()
			languages.length > 0

	methods:

		dropdownEnter: ->
			@mouseOverDropdown = true

		dropdownLeave: ->
			@mouseOverDropdown = false
			@closeDropdown()

		openDropdown: (navItem) ->
			@openWaitId = @$wait 200, =>
				@selectedNav = navItem

		closeDropdown: ->
			clearTimeout @openWaitId
			@$wait 0, => @selectedNav = null unless @mouseOverDropdown

	watch:

		# Close the nav when the route changes
		$route: -> @selectedNav = null

