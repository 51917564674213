// Adding foo web vitals, so that these vitals (CLS, FID, LCP)
// Can get sent to foo software's dashboard for monitoring
// This is accessed both by the nuxt and shopify side
import {getCLS, getFID, getLCP} from 'web-vitals';

function collectVitals(metric) {
  const url = 'https://www.foo.software/api/vitals';
  const body = JSON.stringify({
    accountId: '5f86043c94bddc0025bc1142',
    name: metric.name,
    url: window.location.href,
    value: metric.value
  });

  // `sendBeacon` or fallback to `fetch`
  (navigator.sendBeacon && navigator.sendBeacon(url, body)) ||
  fetch(url, {
    body,
    method: 'POST',
    keepalive: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function captureWebVitals() {
  getCLS(collectVitals);
  getFID(collectVitals);
  getLCP(collectVitals);
}
