import { render, staticRenderFns } from "./placeholder.vue?vue&type=template&id=1d417ac7&scoped=true&lang=pug&"
import script from "./placeholder.vue?vue&type=script&lang=coffee&"
export * from "./placeholder.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d417ac7",
  null
  
)

export default component.exports