import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'

export default
	data: ->
		loading: false
		currentSellingPlan: null
		purchaseType: 'cart'

	computed:
		isSubscribable: -> !!@sellingPlanAllocations?.length
		isSubscribing: -> @isSubscribable && !!@currentSellingPlan

		sellingPlanAllocations: -> @currentVariant?.sellingPlanAllocations

		currentSellingPlanAllocation: ->
			sellingPlan: @currentSellingPlan

		# Get the discounted price based on the chosen selling plan
		subscriptionPrice: ->
			return unless @isSubscribing
			applySubscriptionDiscount @price

		subscriptionDiscount: -> "#{SUBSCRIPTION_DISCOUNT_PERCENTAGE}%"

		# Label for subscribe checkbox
		subscribeAndSave: ->
			if @subscriptionDiscount != "0%"
				# Non-breaking space to force "Save X%" on the same line
				"Subscribe & Save&nbsp#{@subscriptionDiscount}"
			else "Subscribe"

		currentSellingPlanId: -> @currentSellingPlan?.id

		addToSubscription: -> @purchaseType == 'addToSubscription'

		hideFormControls: -> @addToSubscription

		# Bundle related
		productIsBundle: -> !!@product?.bundleVariants?.length

	methods:
		updatePurchaseType: (purchaseType) -> @purchaseType = purchaseType
