// This stores the user's consent group choices
export const ONETRUST_COOKIE_NAME = 'OptanonConsent'

// These are the IDs for the cookie categories
export const ONETRUST_CATEGORIES = {
	NECESSARY: 'C0001',
	PERFORMANCE: 'C0002',
	FUNCTIONAL: 'C0003',
	TARGETING: 'C0004',
}
