import { render, staticRenderFns } from "./collection-layout.vue?vue&type=template&id=71c1d12e&scoped=true&lang=pug&"
import script from "./collection-layout.vue?vue&type=script&lang=coffee&"
export * from "./collection-layout.vue?vue&type=script&lang=coffee&"
import style0 from "./collection-layout.vue?vue&type=style&index=0&id=71c1d12e&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c1d12e",
  null
  
)

export default component.exports