###
Video Metrics mixin
###

export default

	data: ->
		percentViewed: []

	methods:

		# Get video event properties for GA
		getVideoEventProperties: ->
			videoTitle: "PDP Marquee - #{@getVideoTitle()}"
			videoPercent: @getVideoViewedQuarterPercent()

		# Get Video viewed Percent
		getVideoViewedPercent: -> "#{Math.round((@$refs.embeddedVideo?.currentTime/ @$refs.embeddedVideo?.duration) * 100) || 0}%"

		# Get Video viewed quarter percent
		getVideoViewedQuarterPercent: -> switch
			when parseInt(@getVideoViewedPercent()) >= 100 then '1'
			when parseInt(@getVideoViewedPercent()) >= 75 && parseInt(@getVideoViewedPercent()) < 100 then '0.75'
			when parseInt(@getVideoViewedPercent()) >= 50 && parseInt(@getVideoViewedPercent()) < 75 then '0.5'
			when parseInt(@getVideoViewedPercent()) >= 25 && parseInt(@getVideoViewedPercent()) < 50 then '0.25'
			when parseInt(@getVideoViewedPercent()) >= 0 && parseInt(@getVideoViewedPercent()) < 25 then '0'

		# Get Video Filename
		getVideoFileName: ->
			url = new URL(@$refs.embeddedVideo.currentSrc)
			urlSplitBySlash = url.pathname.split('/')
			return urlSplitBySlash[urlSplitBySlash.length - 1] || @$refs.embeddedVideo.currentSrc

		# Get Video Title
		getVideoTitle: -> @alt || @getVideoFileName()

		# On video time update
		onVideoTimeUpdate: ->
			if !@percentViewed.includes(@getVideoViewedQuarterPercent())
				@percentViewed.push(@getVideoViewedQuarterPercent())
				dataLayer.push({ event: 'Video Progress', ...@getVideoEventProperties() })
