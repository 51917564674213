











export default

	props:
		tags: Array

	computed:

		# Get the first badge to display, if there is one
		badge: ->
			badges = @tags?.filter (name) -> name?.match /^Badge_/
			if badges?.length then badges[0].replace 'Badge_', ''


