# Set intial GTM dataLayer state
window.dataLayer = window.dataLayer || []
window.dataLayer.push
	event: 'Hydrate dataLayer'
	appEnv: process.env.APP_ENV

# Create our own $gtm helper when there is no GTM_ID so Cypress tests can
# read the dataLayer on the window.
export default ({}, inject) ->
	unless process.env.GTM_ID
	then inject 'gtm', push: (payload) ->
		window.dataLayer.push payload
