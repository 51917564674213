










import { loadScript } from 'library/services/helpers'

# If these need to be used more, then move them to a constants file
SC_ID="8bbd7823-91c6-4a97-a0c9-087bf9b26098"
SC_SCRIPT_URL="https://s3.us-west-2.amazonaws.com/static.smartcommerce.co/assets/client/TCU4CR/js/smartcart_clif_bar_US_prod.js"
SC_CART_URL="https://ss.click2cart.com/assets/js/smartcart_min.js"
SC_CSS_URL="https://s3.us-west-2.amazonaws.com/static.smartcommerce.co/assets/client/TCU4CR/css/smartcart_clif_bar_US_prod.css"

export default
	props:
		barcode: String

	mounted: ->
		if !!window?.SmartCart
			window.SmartCart.loadSmartSiteElements()
		else
			await @loadScripts()

	data: ->
		SC_ID: SC_ID

	computed:
		_barcode: ->
			if /\d{12}/.test(@barcode)
				return @barcode
			else
				return @formatBarcode @barcode

	methods:
		formatBarcode: (barcode) -> barcode.replace(/-/g,'')

		loadScripts: ->
			Promise.all([loadScript(SC_SCRIPT_URL), loadScript(SC_CART_URL)])
