














import MobileMenu from './mobile-menu'
import keepFocus from 'library/mixins/keep-focus'
import SecondaryMobileMenu from './secondary-mobile-menu'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default
	props: open: Boolean

	mixins: [ keepFocus ]

	data: ->
		bodyHeightOffset: 0

	mounted: ->
		@interval = setInterval(@adjustHeight, 500)

	destroyed: ->
		clearInterval @interval

	components: {
		MobileMenu
		SecondaryMobileMenu
	}

	computed:
		styles: ->
			paddingTop: "#{@bodyHeightOffset}px"

	methods:
		adjustHeight: ->
			if document.body.style.marginTop
				@bodyHeightOffset = parseInt document.body.style.marginTop
			else
				@bodyHeightOffset = 0

		setFocus: ->
			console.log 'setting new focus'
			@reevaluateFocus()

	watch:
		open: (value) ->
			if value == true then @$nextTick ->
				@initFocus()
				disableBodyScroll @$refs.scrollable
			else
				clearAllBodyScrollLocks()

