# Get the first fragment of a sku which represents the product number.
# Note: if changing this, make sure to update other instances as well, like:
# shopify-theme/snippets/checkout-gtm-instrumentor.liquid
export productNumberFromSku = (sku) ->
	if matches = sku?.match?(/^([a-z0-9]+)\-/i)
	then matches[1]
	else sku

# This also rejects products with a no_purchase tag.
export onlyPublishedProducts = (products) ->
	(products || []).filter isPublishedProduct

# This also rejects products with a no_purchase tag.
export isPublishedProduct = (product) -> switch

	# Require a non-null product
	when !product then false

	# The no_purchase tag is used to hide items like free gifts
	when product.tags?.includes 'no_purchase' then false

	# Return only products with URLs, aka, those that have been published to the
	# online store. This only works on production because the URL value is null
	# while the site is password protected.
	# https://community.shopify.com/c/Shopify-APIs-SDKs/Product-onlineStoreUrl/m-p/572566/highlight/true#M38272
	when process.env.APP_ENV == 'prod' and !product.onlineStoreUrl
	then false

	# List the product
	else true
