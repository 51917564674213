import URL from 'url-parse'
import { isShopifyRegex } from 'library/constants'

# Determine if the URL is a Shopify URL
export isShopifyUrl = (url) ->
	return false unless url # The homepage is not a Shopify URL
	return false unless process.env.CART_ENABLED

	# Check simple paths (that could include a query param)
	return isShopifyRegex.test url if url.charAt(0) == '/'

	# Check full URLs
	{ pathname, hostname } = new URL url
	return false unless hostname.includes 'clifbar'

	# Second check if pathname match regex conds
	return isShopifyRegex.test pathname

# Check if string is a path like '/collections' or '/product/chocolate'
# https://stackoverflow.com/questions/65037482/regex-for-a-url-path-that-can-just-be-a-forward-slash
isPath = (string) -> /^(\/|(\/[a-zA-Z0-9\-#\/]+)+)$/.test(string)

# Determine if the URL is a Nuxt URL
# by checking if it's not a Shopify URL and is a path or a url that contains the NUXT APP URL
export isNuxtUrl = (url) ->
	not isShopifyUrl(url) &&
	(isPath(url) || url.indexOf(process.env.NUXT_APP_URL) > -1)

# Make a Shopify URL
{ host: shopifyHost } = new URL process.env.SHOPIFY_URL
export makeShopifyUrl = (url) ->
	urlObj = new URL url
	urlObj.set 'host', shopifyHost
	urlObj.set 'protocol', 'https'
	return urlObj.toString()

# Make a Nuxt URL
{ host: nuxtHost } = new URL process.env.NUXT_APP_URL
export makeNuxtUrl = (url) ->
	urlObj = new URL url
	urlObj.set 'host', nuxtHost
	unless nuxtHost.match 'localhost' then urlObj.set 'protocol', 'https'
	return urlObj.toString()

# Format the URL of Craft image asset for imgix. A string path or a Craft asset
# object are expected as `image`.
export makeImgixUrl = (image, width) ->
	return unless image
	origin = 'https://clifbar-world.imgix.net'
	path = switch

		# Make a path to the image that is resolvable by the imgix origin. For
		# backwards compatability, we expect a path as the `image` argument.
		when typeof image == 'string' then image

		# If an object, expect an Asset entry and get the path from the URL. Using
		# the "url" property rather than "path" so the subdir is available.
		when image.url then (new URL(image.url)).pathname.substr(1)

		# Throw if URL isn't either of the two previous options
		else throw "Cannot make imgix URL for #{JSON.stringify(image)}"

	# Build URLs
	return "#{origin}/#{path}?auto=format&auto=compress" unless width
	"#{origin}/#{path}?w=#{width}&fit=max&auto=format&auto=compress"

# Test if an image is a Shopify image. Accepts an object with src or url values
# or the url directly
# https://changelog.shopify.com/posts/changes-to-asset-urls
export isShopifyImage = (ref) ->
	url = ref?.url || ref?.src || ref
	return url?.match?(/shopify|cdn\/shop/)

# Get the current apex (root) domain (aka clifbar.com)
# https://regex101.com/r/MwsZpF/1
export currentApexDomain = ->
	location.hostname.match(/(?:^|\.)((?:[^.]+\.)?[^.]+)$/)[1]

# Make object of query params from current url
export currentQueryParams = ->
	url = new URL window.location.href, true
	return url.query

# Should we fetch statically generated data
export shouldUseStaticData = ->
	return false if process.env.IS_NUXT
	process.env.APP_ENV in ['prod', 'testing'] and
	window? and isShopifyUrl window.location.href
