

export default () => {
    if (process.server) {
        return;
    }

    const detect = function () {
          return document.documentElement.style.objectFit != null;
        };

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "objectFitPolyfill"
        ], function (require) {
            const polyfill = require("objectFitPolyfill");

            resolve();
        });
    });
}