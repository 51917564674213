
























import hasPricespiderBtn from 'library/mixins/has-pricespider-btn'
import hasVideoModalBtn from 'library/mixins/has-video-modal-btn'

export default

	mixins: [ hasPricespiderBtn, hasVideoModalBtn ]

	props:
		buttons: Array
		linkGtm: String
