


































import disableScrollWhenOpen from 'library/mixins/disable-scroll-when-open'
import keepFocus from 'library/mixins/keep-focus'
import { wait } from 'library/services/helpers'
export default

	mixins: [
		disableScrollWhenOpen
		keepFocus
	]

	props:
		type:
			type: String
			validator: (val) -> val in ['video', 'compact']
		closeable:
			type: Boolean
			default: true
		maintain:
			type: Boolean
			default: false
		# The labelling element id to set aria-labelledby or aria-label
		# Right now only add aria-labelledby per h2 in slot
		labelledBy:
			type: String
			default: null
		# Save the element that triggers modal open
		initializer:
			type: null
			default: null

	data: ->
		open: true
		zIndices: {}

	mounted: ->
		@initFocus()

		juCons = document.querySelectorAll('.ju_Con')

		zIndices = @zIndices

		juCons.forEach((juCon, idx) ->
			zIndices[idx] = juCon.style.zIndex
			juCon.style.zIndex = 10
		)

	destroyed: ->
		clearInterval @interval

		juCons = document.querySelectorAll('.ju_Con')

		zIndices = @zIndices

		juCons.forEach((juCon, idx) ->
			juCon.style.zIndex = zIndices[idx]
		)

		# Return focus to the initializer on destroy
		if @initializer then @initializer.focus()

	computed:

		# Classes on the slot
		slotClasses: -> "type-#{@type}" if @type

	watch:

		# Emit event when closing
		open: -> @$emit 'close' unless @open

	methods:

		# Remove it after the transition ends
		remove: ->
			@$emit "destroy"  # Got destroyed?  Better tell your parents.
			@$destroy()

			if !@maintain then @$el.remove()
