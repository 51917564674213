###
Assemble the data needed to render the customer account menu
###
import { getOrderStatuses } from 'library/services/shopify/customer'
import { ordergrooveApi } from 'library/services/ordergroove/helpers'

export state = ->

	# Store the count of unfulfilled orders
	unfulfilledOrderCount: null

	# Subscription data
	unsentSubscriptions: null

	# Mark when data used by the module has been fully fetched
	hydrating: false
	hydrated: false

export mutations =

	# Set the unfulfilledOrderCount
	setUnfulfilledOrderCount: (state, count) ->
		state.unfulfilledOrderCount = count

	# Set the subscription status values
	setUnsentSubscriptions: (state, orders) ->
		state.unsentSubscriptions = orders.map (order) => {
			...order
			shipmentDate: new Date order.shipmentDate
		}

	# Mark has hydrated
	isHydrating: (state) -> state.hydrating = true
	isHydrated: (state) -> state.hydrated = true

export getters =

	# Helper for accessing the access token
	accessToken: (state, getters, { customer }) -> customer.accessToken

	# Build the links list that's used for the dashboard flyout and within /
	# account. This is so that Payment Methods and Customer Service appear on
	# same row when in 2 column grid, since their text may wrap
	links: (state, getters, { route }, rootGetters) -> [
		{
			title: 'My Account'
			to: '/account'
			icon: 'account'
		}
		{
			title: 'Orders'
			to: '/account?view=orders'
			icon: 'package'
			count: state.unfulfilledOrderCount || 0
		}
		{
			title: 'Subscriptions'
			to: '/account?view=subscriptions'
			icon: 'refresh'
			count: state.unsentSubscriptions?.length || 0
		}
		{
			title: 'Favorites'
			to: '/favorites'
			icon: 'favorite-solid'
			count: rootGetters['favorites/count'] || 0
		}
		{
			title: 'Payment Method'
			to: '/account?view=payment-method'
			icon: 'credit-card'
		}
		{
			title: 'Customer Service'
			to: '/contact-us'
			icon: 'phone'
		}
		{
			title: 'Addresses'
			to: '/account/addresses'
			icon: 'location'
		}
		{
			title: 'Settings'
			to: '/account?view=settings'
			icon: 'settings'
		}
		# {
		# 	title: 'Newsletter'
		# 	to: '/account/view=newsletter'
		# 	icon: 'mail'
		# }

	# Find the active link
	].map (link) ->
		current = route.path
		current += "?view=#{view}" if view = route.query.view
		current += "?subscriptions" if 'subscriptions' of route.query
		{
			...link
			active: switch
				when current == link.to then true
				when current.startsWith('/account/orders') and
					link.to.includes('view=orders') then true
		}

	# Get the active link
	activeLink: (state, getters) -> getters.links.find ({ active }) -> !!active

export actions =

	# Get all supplementary data that is used in the navigation
	fetch: ({ commit, dispatch, rootState: { customer } }) ->
		commit 'isHydrating'

		# Wait for customer accessToken to be present
		unless customer.accessToken
		then await new Promise (resolve) =>
			unwatch = @watch (({ customer }) -> customer.accessToken), ->
				resolve() and unwatch()

		# Fetch data. Count on favorite's own hydration service to trigger it's
		# load.
		try await Promise.all [
			dispatch 'fetchUnfufilledOrderCount'
			dispatch 'fetchUnsentSubscriptions'
		]

		# Mark hydrated even in case of errors
		finally commit 'isHydrated'

	# Get the unfulfilled order count by getting 1 page of orders and filtering
	# them. I think this is a close enough approximation that doesn't require
	# a Netlify function or fetching a bunch of pages.
	fetchUnfufilledOrderCount: ({ commit, getters: { accessToken } }) ->
		return unless accessToken
		orders = await getOrderStatuses { accessToken }
		unfulfilledOrders = orders.filter (order) ->
			order.fulfillmentStatus == 'UNFULFILLED' and
			order.financialStatus == 'AUTHORIZED' # Thus, exclude voided
		commit 'setUnfulfilledOrderCount', unfulfilledOrders.length

	# Fetch active subscriptions count from the Netlify function that was created
	# to fetch this data from OG API.
	fetchUnsentSubscriptions: ({ commit, getters: { accessToken } }) ->
		return unless accessToken
		orders = await ordergrooveApi 'unsentOrders', accessToken
		commit 'setUnsentSubscriptions', orders

	# Fetch unless already fetching
	fetchUnlessHydrating: ({ state, dispatch }) ->
		return if state.hydrating
		dispatch 'fetch'
