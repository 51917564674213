# ReCAPTCHA verify a submission
export getRecaptchaToken =  -> new Promise (resolve, reject) ->

	# If testing, return a fake token
	return resolve 'fakeToken' if window.Cypress

	# Wait for SDK to load from GTM
	checkForRecaptchaSDK = -> new Promise (resolve) ->
		waitMax = 8000
		waitInterval = 300
		waitCurrent = 0
		runCheck = ->
			if window.grecaptcha then resolve()
			else
				waitCurrent += waitInterval
				if waitCurrent >= waitMax
				then throw "Recaptcha still not loaded after #{waitMax}ms"
				setTimeout runCheck, waitInterval
		runCheck()
	await checkForRecaptchaSDK()

	# When initialized, get the token and return it
	grecaptcha.ready ->
		try
			token = await grecaptcha.execute process.env.RECAPTCHA_SITE_KEY,
				action: 'submit'
			resolve token
		catch e then reject e
