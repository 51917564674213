





















export default
	name: 'SubscriptionCheckbox'

	props:
		label: String
		value: Boolean
		disabled: Boolean
		loading: Boolean

	data: -> state: @value

	watch:
		value: -> @state = @value
		state: -> @$emit 'input', @state

	methods:

		# Handle clicks
		onClick: ->
			return if @loading
			@state = !@state

	computed:
		checked: -> !!@state

