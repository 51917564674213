







export default
	name: 'Divider'

	props: link: Object

