



































import LazyHydrate from 'vue-lazy-hydration'
import GlobalNewsletterForm from 'library/components/global/newsletter-form'
import VueUnorphan from 'vue-unorphan'

export default

	components: { LazyHydrate, GlobalNewsletterForm }

	# We have to register the directive for usage in Liquid
	directives: unorphan: VueUnorphan

	data: ->
		# Form state: init, success, error
		newsletterFormState: 'init'

	methods:

		onNewsletterFormStateChange: (state) -> @newsletterFormState = state

