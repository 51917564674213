













export default
	name: 'item-button'

	props:
		item: Object

