

































import debounce from 'lodash/debounce'
export default

	data: -> width: null

	# React to resizing
	mounted: ->
		@setWidth()
		window.addEventListener 'resize', @setWidth

		# Also, add close trigger listeners
		@$defer => document.addEventListener 'click', @onPossibleClickOutside
		window.addEventListener 'scroll', @closeToolip

	# Cleanup
	beforeDestroy: ->
		window.removeEventListener 'resize', @setWidth
		document.removeEventListener 'click', @onPossibleClickOutside
		window.removeEventListener 'scroll', @closeToolip

	computed:

		# Dynamic styles
		styles: -> width: "#{@width}px"

	methods:

		# Set the width so that it appears flush with the right edge
		setWidth: debounce ->
			{ left, width } = @$el.getBoundingClientRect()
			winWidth = window.innerWidth
			@width = Math.min 375, Math.ceil(winWidth - left) * 2
		, 100, leading: true

		# Trigger close unless the click occured in the tooltip
		onPossibleClickOutside: ({ target }) ->
			@closeToolip() unless target.closest '.guest-tooltip'

		# Tell parent to close the tooltip
		closeToolip: -> @$emit 'close'

