
























import Modal from './modal'
import ButtonList from 'library/components/global/button-list'
export default

	components: { Modal, ButtonList }

	computed:

		# Get the content from vuex
		content: -> @$store.state.globals.subscriptionDetails
