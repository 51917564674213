


import CraftVisual, { getAssetObject, makeSrcset } from './craft-visual'
import { makeImgixUrl } from 'library/services/helpers'

mediaQueries =
	desktop: '(min-width: 768px)'
	mobile: '(max-width: 767px)'

export default
	name: 'ResponsiveVisual'

	# Get the index of the block this may be in
	inject: blockIndex: default: undefined

	props: {

		# Merge craft-visual props
		...CraftVisual.props

		# Make explicit desktop and mobile props
		desktopImage: Object|Array
		mobileImage: Object|Array
		desktopVideo: Object|Array
		mobileVideo: Object|Array

		# Shorthands, for passing a supertable desktop & mobile object
		image: Object|Array
		video: Object|Array

		# Need to be able to pass empty string alt for ADA purposes
		# https://app.asana.com/0/1205224826010742/1206075030714157/f
		alt: String
	}

	# Track when mounting has happened
	data: -> mounted: false
	mounted: -> @mounted = true

	# Add preload link tag
	head: ->
		return unless @isResponsiveImage and @shouldPreload

		# Start two tags with the unique attributes
		link: [
			{
				href: makeImgixUrl @desktop.props.image
				imagesrcset: makeSrcset @desktop.props.image
				media: mediaQueries.desktop
			}
			{
				href: makeImgixUrl @mobile.props.image
				imagesrcset: makeSrcset @mobile.props.image
				media: mediaQueries.mobile
			}

		# Add shared attributes
		].map (attrs) => {
			...attrs
			hid: attrs.href # Prevent rendering the same tag twice
			rel: 'preload'
			as: 'image'
			imagesizes: @sizes || ''
		}

	computed:

		# Which view to show
		isDesktop: -> @$store.getters['layout/isDesktop']
		isMobile: -> @$store.getters['layout/isMobile']

		# Visual configs
		desktop: -> @makeConfig @getAsset('image', 'desktop'),
			@getAsset('video', 'desktop')
		mobile: -> @makeConfig @getAsset('image', 'mobile'),
			@getAsset('video', 'mobile')

		# Do we have unique desktop an mobile configs?
		isResponsive: -> !!(@desktop and @mobile)
		isResponsiveImage: -> !!(@desktop?.props?.image and @mobile?.props?.image)

		# Automatically preload if a critical image
		shouldPreload: ->
			isCriticalImage = @blockIndex < 2
			return @preload ? isCriticalImage

		# The config used when there is both desktop and mobile assets. The video
		# prop will only be set once the viewport can be measured.
		responsiveConfig: ->
			return unless @isResponsive
			{
				...@desktop
				props: {
					...@desktop.props
					video: @responsiveVideo

					# Preloading and loading are handled in this component
					preload:
						if @isResponsiveImage
						then false else @$props.preload
					lazyload:
						if @isResponsiveImage and @shouldPreload
						then false else @$props.lazyload
				}
				class: 'responsive-visual'
				style:
					'--desktop-aspect': @makeAspectStyle 'desktop'
					'--mobile-aspect': @makeAspectStyle 'mobile'
			}

		# If there are both desktop and mobile videos, wait till the page is
		# mounted to decide which to show.  This prevents issues with differences
		# between SSR and client. Only display video designated for the platform.
		responsiveVideo: ->
			desktop = @desktop.props.video
			mobile = @mobile.props.video
			return unless @mounted
			switch
				when @isDesktop then desktop
				when @isMobile then mobile

		# Make responsive sources
		responsiveSources: ->
			return unless @isResponsiveImage
			[
				{
					attrs:
						media: mediaQueries.desktop
						srcset: makeSrcset @desktop.props.image
				}
				{
					attrs:
						media: mediaQueries.mobile
						srcset: makeSrcset @mobile.props.image
				}
			]

	methods:

		# Get the specified asset
		# mediaType: image|video
		# viewportType: mobile|desktop
		getAsset: (mediaType, viewportType) ->

			# Get from supertable shorthand object
			if superTableAsset = getAssetObject @[mediaType]
				return getAssetObject superTableAsset[viewportType]

			# Get explicit value
			else return getAssetObject @[viewportType + @$ucFirst(mediaType)]

		# Make the aspect css style
		makeAspectStyle: (viewportType) ->
			return unless image = @[viewportType].props.image
			aspect = image.width / image.height
			return "#{1 / aspect * 100}%"

		# Make the config object for the create function by keeping all data and
		# props except for replacing desktop and mobile with the asset itself
		makeConfig: (image, video) ->
			return unless image or video
			on: loaded: => @$emit 'loaded'
			props: {
				...@$props
				image
				video
				desktopImage: undefined
				mobileImage: undefined
				desktopVideo: undefined
				mobileVideo: undefined
			}

		play: -> @$refs.craftVisual.play()

		pause: -> @$refs.craftVisual.pause()

	# Make the appropriate visual instance
	render: (create) ->

		# Create visual for the current viewport width
		if @desktop || @mobile
			unless @isResponsive
			then create CraftVisual, @desktop || @mobile, @$slots.default
			else create CraftVisual, {
				...@responsiveConfig
				scopedSlots: ['image-source']: =>
					@responsiveSources.map (data) -> create 'source', data
				ref: "craftVisual"
			}, @$slots.default

		# No assets were discovered, so explicitly clear the asset props
		else create CraftVisual, { props: {
			...@$props
			image: undefined
			video: undefined
		}}, @$slots.default
