

























import CountBadge from 'library/components/global/count-badge'
export default

	components: { CountBadge }

	props:
		title: String
		to: String
		icon: String
		count: Number
		active: Boolean
		small: Boolean
		component: String # Used in GTM event

	computed:
		# a11y: https://app.asana.com/0/1205224826010742/1206075082390068
		ariaCurrent: -> if @active then 'page' else null

