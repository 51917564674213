###
Boot up cart related logic
###
import { applyAutomaticCartItems } from 'library/services/automatic-cart-items'
import { addGtmCartSubscribers } from 'library/services/gtm/cart-events'
import { addValidateCartItemsSubscribers } from 'library/services/validate-cart-items'
import { lazyHydrateCart } from 'library/services/hydrate-cart'
import { syncBundlesToCartAttributes } from 'library/services/shopify/bundling/sync-cart-attribute'
export default ({ app, store }) ->
	applyAutomaticCartItems store
	addGtmCartSubscribers store
	addValidateCartItemsSubscribers store
	lazyHydrateCart store
	syncBundlesToCartAttributes store
