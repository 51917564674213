










































import Price from 'library/components/global/price'
import VariantItemRow from 'library/components/global/variant-item-row'

export default

	model:
		prop: 'currentVariant'
		event: 'variantpicked'

	mounted: ->
		document.addEventListener 'click', @hideSelectBox

	destroyed: ->
		document.removeEventListener 'click', @hideSelectBox

	props:
		product: Object
		currentVariant: Object
		bundleVariants: Array
		showPrice:
			type: Boolean
			default: false

	components: {
		VariantItemRow
		Price
	}

	data: ->
		selectBoxVisible: false

	computed:
		currentSelectionTitle: -> @currentVariant?.title

		currentSelectionPrice: -> @currentVariant?.price

		currentSelectionId: -> @currentVariant?.id

	methods:
		# Allows component to be used with liquid files (has beef with v-model)
		handleVariantClick: (variant) ->
			@$emit('variantpicked', variant);
			@selectBoxVisible = false

		showSelectBox: (e) ->
			@selectBoxVisible = true

		hideSelectBox: (e) ->
			if !e.target.closest('.variant-select-box')
				@selectBoxVisible = false

	watch:
		selectBoxVisible: (val) ->
			return unless val
			selected = @$refs.variantItemRow.find (row) => row.$el.ariaSelected
			if selected then @$nextTick => selected.$el.focus()
