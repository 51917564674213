import HeaderLink from './items/header-link'
import TextLink from './items/text-link'
import ImageLink from './items/image-link'
import Divider from './items/divider'
import Header from './items/header'
import Button from './items/button'
import Spacer from './items/spacer'

export default
	functional: true

	name: 'column-item'

	props:
		item: Object
		type: String

	# Render the appropriate component
	render: (create, { props: { type, item } }) ->
		data = props: { item }

		switch type
			when 'headerLink' then create HeaderLink, data
			when 'textLink' then create TextLink, data
			when 'imageLink' then create ImageLink, data
			when 'header' then create Header, data
			when 'divider' then create Divider, data
			when 'button' then create Button, data
			when 'spacer' then create Spacer, data
