






















import SearchBox from 'library/components/global/search-box'

export default

	components: {
		SearchBox
	}

	computed:

		suggestions: -> @$store.state.globals.searchSuggestions?.children || []

		# Simulate search box block
		searchBoxBlock: ->
			label: ''
			placeholder: @$t('search.search_here')

	methods:

		searchUrl: (term) -> '/search?query=' + term.title

