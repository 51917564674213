

















export default

	data: ->
		searchOpen: false

	methods:
		openDropdown: -> @$emit 'open', 'search'
		closeDropdown: -> @$emit 'close', 'search'
