























































import cartLine from 'library/mixins/cart-line'
import SubscriptionOptIn from 'library/components/global/subscription-opt-in'
export default

	mixins: [ cartLine ]

	components: { SubscriptionOptIn }

	data: -> component: @$GTM_COMPONENTS.FLYOUT_CART

	computed:
		itemWrapClasses: -> 'is-loading' if @loading

