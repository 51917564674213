import { login, renew, fetch, logout } from 'library/services/shopify/customer'
import { cookie, getShopifyId } from 'library/services/helpers'
import { CUSTOMER_ACCESS_TOKEN } from 'library/constants'
import axios from 'axios'

# Stores the Shopify "customer" object
export state = ->

	# Customer info
	id: null # As an integer ID
	email: null
	firstName: null
	lastName: null
	defaultAddress: null # Will be null for new customers
	acceptsMarketing: null
	inactiveSubscriptionIds: []

	# Shopify auth data, for making future requests
	accessToken: null
	expiresAt: null

	# True after intial hydration pass is complete
	hydrated: false

export getters =

	# Helpers for declaratively checking whether customer is logged in
	isAuthenticated: (state) -> !!state.accessToken
	isGuest: (state) -> !state.accessToken

	# Whether this customer is allowed to purchase subscriptions
	canPurchaseSubscriptions: (state, getters, rootState, rootGetters) ->
		return false if rootGetters['cart/evDiscount'] > 0
		return true # Everyone else can

export mutations =

	# Set auth vars like accessToken
	setAuth: (state, { accessToken, expiresAt }) ->
		Object.assign state, { accessToken, expiresAt }

	# Set customer data
	setCustomer: (state, customer) ->
		Object.assign state, {
			...customer

			# Convert the customer id into it's integer form for backwards compat
			id: getShopifyId customer.id

			# Store ids from metafield as a simple array of ids
			inactiveSubscriptionIds: do ->
				return [] unless val = customer.inactiveSubscriptionIds?.value
				return JSON.parse val
		}

	# Update the default address of the customer
	updateDefaultAddress: (state, address) -> state.defaultAddress = address

	# Mark has hydrated
	isHydrated: (state) -> state.hydrated = true

	# Recursively null out all state
	clear: (state) ->
		clearObject = (obj) ->
			for key, val of obj
			then obj[key] = switch typeof val
				when 'object' then clearObject val
				when 'boolean' then false
				else null
			return obj # For recursion
		clearObject state

export actions =

	# Login a customer
	login: ({ commit, dispatch }, { email, password }) ->
		auth = await login { email, password }
		await dispatch 'handleAuth', auth

	# Login a customer from cookie state. If it fails, silently ignore (they
	# won't be logged in) though this _does_ count as being hydrated.
	loginFromStorage: ({ commit, dispatch }) ->

		# Check for access token on cookie
		unless accessToken = cookie.get CUSTOMER_ACCESS_TOKEN
			await dispatch 'hydrateFromLegacyCookie'
			return commit 'isHydrated'

		# Try to renew the token, which will fail if it's a bad token
		try auth = await renew { accessToken }
		catch e
			cookie.remove CUSTOMER_ACCESS_TOKEN
			return commit 'isHydrated'

		# Finish hydrating the customer
		await dispatch 'handleAuth', auth

	# Login a customer using only their hashed customer id
	loginFromShopifySession: ({ commit, dispatch }) ->

		# Get the Shopify data needed for logging them in
		unless payload = window.CUSTOMER_LOGIN_PAYLOAD
		then throw 'Missing CUSTOMER_LOGIN_PAYLOAD'

		# Get access token from Netlify function
		endpoint = process.env.NUXT_APP_URL
		url = "#{endpoint}/.netlify/functions/create-customer-access-token"
		{ data: auth } = await axios.post url, payload

		# Finish hydrating the customer
		await dispatch 'handleAuth', auth

	# Support the old cookie from `share-customer` during transition to new
	# Storefront access token based login
	hydrateFromLegacyCookie: ({ commit }) ->
		return unless customerInfo = cookie.get 'customer_info'
		customerInfo = JSON.parse customerInfo
		commit 'setCustomer',
			id: customerInfo.id
			email: customerInfo.email
			firstName: customerInfo.firstName
			lastName: customerInfo.lastName
			defaultAddress:
				firstName: customerInfo.firstName
				lastName: customerInfo.lastName
				address1: customerInfo.address
				city: customerInfo.city
				state: customerInfo.state
				country: customerInfo.country
				zip: customerInfo.zip
				phone: customerInfo.phone

	# DRY up code that handles the customerAccessToken from Shopify
	handleAuth: ({ commit, dispatch }, { accessToken, expiresAt } ) ->

		# Store auth values
		commit 'setAuth', { accessToken, expiresAt }

		# Persist across sessions
		cookie.set CUSTOMER_ACCESS_TOKEN, accessToken, expires: new Date expiresAt

		# Finish hydrating customer
		await dispatch 'fetch'

	# Get customer data using accessToken from state
	fetch: ({ commit, state: { accessToken } }) ->
		customer = await fetch { accessToken }
		commit 'setCustomer', customer
		commit 'isHydrated'

	# Logout a customer using accessToken from cookie. Catching errors because
	# it's a valid use case that the access token has expired and couldn't be
	# used to logout the user.
	logoutFromStorage: ({ commit, dispatch }) ->
		return unless accessToken = cookie.get CUSTOMER_ACCESS_TOKEN
		cookie.remove CUSTOMER_ACCESS_TOKEN
		try await logout { accessToken }
		catch error then console.error error
		commit 'clear'
