












import Divider from './items/divider'
import Header from './items/header'
import LargeLink from './items/large-link'
import StandardLink from './items/standard-link'
export default

	props: link: Object

	methods: forward: -> @$emit 'forward'

	computed: componentType: -> switch @link.mobileNavType
		when 'largeLink' then return LargeLink
		when 'divider' then return Divider
		when 'header' then return Header
		when 'standardLink' then return StandardLink

