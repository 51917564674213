























export default

	name: 'productSubscriptionRadio'

	# Use "choice" as the v-model prop
	model: prop: 'choice'

	props:
		value: String
		choice: String

	computed:

		# Is the radio checked
		isChecked: -> @choice == @value

	methods:

		# Fire v-model event on click
		onClick: -> @$emit 'input', @value
