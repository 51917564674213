import axios from 'axios'
import getGlobals from 'library/queries/craft/globals.gql'
import { shouldUseStaticData } from 'library/services/helpers'

# Simple global vars
export state = ->

	# Fetched data
	automaticCartItems: null
	desktopNavigation: null
	favorites: null
	footerNavigation: null
	freeShippingItems: null
	googleOptimize: null
	meta: null
	powerReviewsLoaded: null
	primaryMobileMenu: null
	searchSuggestions: null
	secondaryMobileMenu: null
	subscriptionDetails: null
	evModals: null

	# Load tracking
	hydrated: false

export getters =

	# Make simple array of paths running experiments
	googleOptimizePaths: (state) ->
		(state.googleOptimize?.paths || [])
		.map ({ path }) -> path

export mutations =

	# Merge the payload into the state
	set: (state, payload) ->
		Object.assign state, payload
		state.hydrated = true

	# Set the power reviews state
	setPowerReviewsLoaded: (state, val) -> state.powerReviewsLoaded = val

export actions =

	# Get navigation data fresh from SSG or fresh depending on the app env
	fetch: ({ commit }) ->
		commit 'set', if shouldUseStaticData()
			globalsUrl = "#{process.env.NUXT_APP_URL || ''}/data/globals.json"
			(await axios.get globalsUrl).data
		else await @$craft.execute query: getGlobals
