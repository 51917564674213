###
A migration method to be used to help with the conversion of using
Shopify's Checkout API to Shopify's Cart API
###
import { cookie } from 'library/services/helpers'
import fetchQuery from 'library/queries/shopify/checkout/fetch.gql'
import { execute } from './storefront'
import { CHECKOUT_KEY, SUBSCRIPTIONS_KEY } from 'library/constants'
import * as cartApi from './cart'

# Throw errors if found in the response, else map the checkout object
handleCheckoutMutation = ({ mutation: response }) ->
	if response.checkoutUserErrors?.length
	then throw JSON.stringify response.checkoutUserErrors
	if response.userErrors?.length
	then throw JSON.stringify response.userErrors
	return response.checkout

# Lookup a checkout
fetchCheckout = (id) ->
	{ node } = await execute
		query: fetchQuery
		variables: { id }
	return node

# Update a line item
updateQuantity = (checkoutId, lineItemId, quantity) ->
	handleCheckoutMutation await execute
		query: updateQuery
		variables:
			checkoutId: checkoutId
			lineItems: [
				id: lineItemId
				quantity: parseInt quantity
			]

export migrateCheckoutCart = () ->

	# Lookup if there is a previous cookie for the Checkout API
	# Return if not found
	return unless checkoutId = cookie.get CHECKOUT_KEY

	# Get our old Checkout object
	checkout = await fetchCheckout checkoutId

	# Get any subscriptions that may still be in cookies
	subscriptions = if subscriptionsCookie = cookie.get SUBSCRIPTIONS_KEY
	then JSON.parse subscriptionsCookie else []

	# Merge our two objects together
	lineItems = [
		# Map the checkout lines
		...checkout.lineItems.map (item) ->
			variantId: item.variant.id
			quantity: item.quantity
		# Map the subscription lines
		...subscriptions.map (item) ->
			variantId: item.id
			quantity: item.quantity
			sellingPlanId: item.sellingPlan
	]

	# Create a new Cart API cart
	cart = await cartApi.create()

	# Add All the old line items
	cart = await cartApi.addLines {cartId: cart.id, lines: lineItems}

	# Remove our old cookies
	cookie.remove CHECKOUT_KEY
	cookie.remove SUBSCRIPTIONS_KEY

	# Return our new cart
	return cart
