

















import CountBadge from 'library/components/global/count-badge'
import FavoriteIcon from './favorite-icon'
import FavoritesFlyout from 'library/components/layout/favorites-flyout/favorites-flyout'
export default

	components: {
		CountBadge
		FavoriteIcon
		FavoritesFlyout
	}

	computed:

		# The number of favorited items
		count: -> @$store.getters['favorites/count']

		# Has the user favorited products?
		hasFavorites: -> @count > 0

	methods:

		# Open the sidebar flyout if the user has favorites. Else, we'll push them
		# to the favorites page
		openFlyout: ->
			if @hasFavorites
			then await @$mountOnBody FavoritesFlyout, parent: this
			else @$router.push path: '/favorites'

