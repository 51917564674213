
































import AddToCartBtn from './add-to-cart-btn'
import Price from 'library/components/global/price'
import SubscriptionOptions from '../subscribing/subscription-options'
import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'
import subscribableProductMixin from 'library/services/shopify/subscribing/subscribable-product-mixin'

export default
	nmae: 'subscriberPurchase'

	mixins: [ subscribableProductMixin ]

	inject:
		onQuickShopModal: default: false
		defaultToSubscribe: default: false

	components: { AddToCartBtn, Price, SubscriptionOptions }

	props:
		variant: Object
		quantity: # Quantity to add (typically 1 for card, responsive if modal)
			type: Number
			default: 1

	data: ->
		choice: if @defaultToSubscribe then 'subscription' else 'cart'
		adding: false

	computed:

		# The label on the button
		cta: -> switch
			when !@variant.available then @$t('product.unavailable')
			when @isSubscribing then switch
				when @subscribed then @$t('product.manage_subscription')
				else @$t('product.add_to_subscription')
			when @choice == 'cart' then @$t('product.add_to_cart')

		# Apply subscription pricing as a compare at price
		pricing: ->
			if @choice == 'cart' then @variant
			else
				price: applySubscriptionDiscount @variant.price
				compareAtPrice: @variant.price

		subscribeText: -> switch
			when @subscribed
				'Existing <br>Subscription'
			when @onQuickShopModal
				"Subscribe & <br>Save #{SUBSCRIPTION_DISCOUNT_PERCENTAGE}%"
			else "Add To My <br>Next Delivery"

		# Backwards compatibility
		currentVariant: -> @variant

	watch:

		# Relay the current price
		pricing:
			immediate: true
			handler: -> @$emit 'pricing', @pricing

	methods:

		# Delegate what to do on click based on state
		onClick: ->
			if @isSubscribing then return
			else @addToCart()

		# Add the variant to the cart
		addToCart: ->
			@adding = true
			await @$store.dispatch 'cart/addItem', {
				variantId: @$getShopifyId @variant.id
				@quantity
				component: if @onQuickShopModal then 'Quick Shop Modal' else 'Product card'
			}
			@$emit 'lineAdded', { @variant, @quantity }
			@$openCart(@$el) unless @onQuickShopModal
			@adding = false
