import DefaultLayout from './default-layout'
import CollectionLayout from './collection-layout'
import ProductCarouselLayout from './product-carousel-layout'
import QuickShopLayout from './quick-shop-layout'

export default
	functional: true

	name: 'placeholder-layout'

	props: type: String

	# Render the appropriate component
	render: (create, { props: { type, item } }) ->
		data = props: { item }

		switch type
			when 'paragraph' then create DefaultLayout, data
			when 'collection-layout' then create CollectionLayout, data
			when 'product-carousel' then create ProductCarouselLayout, data
			when 'quick-shop' then create QuickShopLayout, data
