










import PlaceholderLayout from './placeholders/_placeholder-layout'
export default
	props:
		type:
			type: String
			default: 'paragraph'

	components: {
		PlaceholderLayout
	}

