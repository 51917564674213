

















export default
	name: 'text-link'

	props:
		item: Object

	inject: menuTitle: default: ''

