/**
 * Constants and helpers for localization, writted in JS for importability into
 * nuxt.config.coffee
 */
import uniqBy from 'lodash/uniqBy'
import { resolve, dirname } from 'path'

// List of supported locales used by the locale switcher as well as
// nuxt/i18n package.  The handle should match the CMS site handle that stores
// the content for that locale.
const locales = [
	{
		name: 'Australia',
		handle: 'en_AU',
		url: 'https://www.clifbar.com.au/'
	},
	{
		name: 'Austria',
		handle: 'de_AT',
		url: 'https://www.clifbar.de'
	},
	{
		name: 'Belgium',
		handle: 'de_BE',
		url: 'https://www.clifbar.be'
	},
	{
		name: 'Canada',
		handle: 'en_CA',
		url: 'https://www.clifbar.ca'
	},
	{
		name: 'Canada',
		handle: 'fr_CA',
		url: 'https://fr.clifbar.ca'
	},
	{
		name: 'France',
		handle: 'fr_FR',
		url: 'https://www.clifbar.fr'
	},
	{
		name: 'Germany',
		handle: 'de_DE',
		url: 'https://www.clifbar.de'
	},
	{
		name: 'Ireland',
		handle: 'en_IE',
		url: 'https://www.clifbar.co.uk'
	},
	{
		name: 'Italy',
		handle: 'it_IT',
		url: 'https://www.clifbar.it'
	},
	{
		name: 'Luxembourg',
		handle: 'de_BE',
		url: 'https://www.clifbar.be'
	},
	{
		name: 'Netherlands',
		handle: 'nl_NL',
		url: 'https://www.clifbar.nl'
	},
	{
		name: 'New Zealand',
		handle: 'en_NZ',
		url: 'https://www.clifbar.co.nz'
	},
	{
		name: 'Portugal',
		handle: 'pt_PT',
		url: 'https://www.clifbar.pt'
	},
	{
		name: 'Spain',
		handle: 'es_ES',
		url: 'https://www.clifbar.es'
	},
	{
		name: 'Sweden',
		handle: 'se_SE',
		url: 'https://www.clifbar.se'
	},
	{
		name: 'Switzerland',
		handle: 'de_CH',
		url: 'https://www.clifbar.de'
	},
	{
		name: 'United Kingdom',
		handle: 'en_GB',
		url: 'https://www.clifbar.co.uk'
	},
	{
		name: 'United States',
		handle: 'en_US',
		url: 'https://www.clifbar.com'
	}
]

// Make the configuration need for nuxt/i18n
export function makeNuxtLocalesOption() {
	const i18nLocales = locales.map(({handle, url}) => {
		return {
			code: handle,
			iso: handle.replace('_', '-'),
			domain: url,
			file: resolve(__dirname, './static-translations.coffee'),
		}
	})

	// Get rid of references to the same CMS_SITE
	return uniqBy(i18nLocales, ({ code }) => code)
}

// Make the configuration expected for the locale switcher, Right now this
// is just rendering the list of locales. Originally it was a de-duplicated
// list, but there's more than 1 country pointing to a locale, so because
// of that, countries were missing from the list
// https://app.asana.com/0/1200777795870740/1202126869925157/f
export function makeLocaleSelectorOptions() {
	const localesWithCountyCode = locales.map(locale => ({
		...locale,
		countryCode: locale.handle.split('_')[1].toLowerCase(),
	}))
	return localesWithCountyCode.filter(locale => {
		// strip out the fr_ca site from the selector options list
		return locale.handle != 'fr_CA'
	});
}

// Make the configuration expected for the language switcher in the header
export function makeLanguageSwitcherOptions() {
	return locales.filter(locale => {
		// Return if the country code of the handle matches current cms site
		return locale.handle.slice(-2) == process.env.CMS_SITE.slice(-2)
	})
}

export function getCurrentCountry() {
	let [locale] = locales.filter(locale => {
		return locale.handle == process.env.CMS_SITE
	})
	return locale.handle.slice(-2)
}

// Get the current language of the site based on the CMS_SITE variable
export function getCurrentLanguage() {
	let [locale] = locales.filter(locale => {
		return locale.handle == process.env.CMS_SITE
	})

	return locale.handle.slice(2)
}

// Get the current ISO code, which is different than CMS_SITE which uses
// underscores.
export function getCurrentIsoCode() {
	if(['prod', 'uat', 'dev'].includes(process.env.CMS_SITE)) {
		return 'en-US'
	} else {
		return process.env.CMS_SITE.replace('_', '-')
	}
}
