










export default

	props:
		height: Number
		offset:
			type: Number
			default: 0

	data: ->
		scrollingUp: false
		scrollY: 0
		tweenOffset: false

	# Add scroll listners
	mounted: -> window.addEventListener 'scroll', @onScroll
	destroyed: -> window.removeEventListener 'scroll', @onScroll

	computed:

		# Is the page currently loading. This won't exist on Shopify.
		loading: -> @$store.state.loading?.pageLoading

		# If using smooth scrolling, make this feel like it scrolls off screen
		# naturally
		transformOffset: ->
			if (@scrollingUp and @tweenOffset) or @loading then 0
			else -1 * Math.min @height + @offset, @scrollY

		# Has the scroll moved past the header
		scrolledPast: -> @scrollY > @height + @offset

		# At the top of of the page
		atTop: -> @scrollY == 0

		# Always show the background color when down the page
		showBackground: -> @loading or not @atTop

		# Build CSS
		styles: ->
			# console.log @transformOffset
			transform: "translateY(#{@transformOffset}px)"
			top: "#{@offset}px"
		classes: ->
			'tween-transfrom': @tweenOffset
			'show-bkgd': @showBackground

	watch:

		# Don't allow tweening of the header until the first scroll up. This is
		# done to prevent a tween happening when the user first scrolls past the
		# header height
		scrollingUp: -> @tweenOffset = true if @scrollingUp and @scrolledPast

		# Tween while loading and reset when done loading
		loading: -> @tweenOffset = @loading

		# When the user returns to the top, reset the tweening
		atTop: -> @tweenOffset = false unless @atTop

	methods:

		# Store scroll variables
		onScroll: ->
			now = window.scrollY
			@scrollingUp = now != 0 and now < @scrollY
			@scrollY = now

