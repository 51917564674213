###
Validate the cart items
- currently just checking if a bundle has been disabled for some reason
###

import { BUNDLE_ATTRIBUTE_KEY } from 'library/constants'

export addValidateCartItemsSubscribers = (store) ->

	store.watch ((state) ->
		isHydrated: state.cart.hydrated && state.bundles.hydrated
		lineItems: state.cart.lineItems
		bundles: state.bundles.bundles
	)
	, (({isHydrated, lineItems, bundles}) ->
		# Only clean up once bundles and line items are hydrated
		return unless isHydrated

		# Find line items we can't resolve and build array
		invalidItems = lineItems.filter (lineItem) ->
			# If our line item doesn't have a bundle attribute, then we don't have
			# a validation in the current implementation
			return false unless bundleAttribute = lineItem.attributes.find (attribute) ->
				attribute.key == BUNDLE_ATTRIBUTE_KEY

			# If line item is a bundle and that bundle sku isn't in our bundles
			# then it's invalid (likely has been drafted)
			return !bundles.some (bundle) ->
				bundle.sku == bundleAttribute.value

		return unless invalidItems?.length

		# Remove array of line items we can't resolve from the cart
		await store.dispatch 'cart/removeInvalidItems', {
			lineIds: invalidItems.map (item) -> item.id
		}
	)
