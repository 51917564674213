import { GTM_EVENTS } from 'library/constants'

export fireCarouselSlideEvent = (component, slide) ->
	window?.dataLayer?.push({
		event: GTM_EVENTS.CAROUSEL_SLIDE_VIEW
		component
		slide
	})

export fireCarouselVideoEvent = (component, videoTitle, videoDuration, videoPercent) ->
	window?.dataLayer?.push({
		event: GTM_EVENTS.CAROUSEL_VIDEO_VIEW
		component
		videoTitle
		videoDuration
		videoPercent
	})