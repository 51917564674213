












import { makeLanguageSwitcherOptions } from 'library/services/i18n/locales'

export default

	computed:

		showLangSwitcher: -> return @languages.length - 1

		# Get the array of languages and map to return more easily consumed data
		# And remove duplicates by handle
		languages: -> makeLanguageSwitcherOptions().map((language) => ({
			...language
			url: language.url + @$route.fullPath
			abbreviation: language.handle.split('_')[0].toUpperCase()
		})).filter((v, i, a) => a.findIndex((v2) => (v2.handle == v.handle)) == i)


		# Determine the active country, ignoring language
		activeCountryCode: -> @$i18n.defaultLocale

		# Get the path for the locale flag image
		localeFlag: ->
			"/imgs/flags/#{@activeCountryCode.split('_')[1].toLowerCase()}.svg"

