


















export default

	# Switch to initials if exceeding this value
	props: maxlength: Number

	computed:

		# Vuex accessors
		customer: -> @$store.state.customer

		# Make their name, applying the maxlength prop
		name: ->
			return unless @customer.hydrated
			{ firstName, lastName } = @customer
			return unless firstName and lastName
			if @maxlength and firstName.length > @maxlength
			then (firstName.charAt(0) + lastName.charAt(0)).toUpperCase()
			else firstName

