




































import Price from 'library/components/global/price'
import ProductFavorite from 'library/components/global/favorites/product-favorite'
import QuickAddBtn from 'library/components/global/buttons/quick-add-btn'
export default

	components: { Price, ProductFavorite, QuickAddBtn }

	inheritAttrs: false
	props:
		title: String
		handle: String
		images: Array
		variants: Array

	computed:

		# The primary variant is the first one
		primaryVariant: -> @variants?[0]

		# Build URL
		url: -> "/products/#{@handle}"

		# Is the cart loading? (aka, we don't know if this product is in the cart)
		cartLoading: -> !@$store.state.cart.hydrated

