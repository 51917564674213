





























import CheckoutButton from 'library/components/global/buttons/checkout-button'
import FlyoutSidebar from '../flyout-sidebar'
import ProductQuickAddCard from './product-quick-add-card'
import { bundleRollupAll } from 'library/services/shopify/bundling/rollupProduct'
export default
	name: 'FavoritesFlyout'

	components: {
		CheckoutButton
		FlyoutSidebar
		ProductQuickAddCard
	}

	data: ->
		loading: false
		products: []

	mounted: ->

		# Load cart data if it hasn't been, so quick add buttons can show the
		# proper state
		@$store.dispatch 'cart/fetchUnlessHydrated'

		# Fire GTM events
		@$gtm.push
			event: @$GTM_EVENTS.OPEN_SIDEBAR
			component: @$GTM_COMPONENTS.FAVORITES_FLYOUT

	computed:

		# Get the most recent 5 favorited products
		recentProductHandles: ->
			[...(@$store.state.favorites.products || [])]
			.reverse().slice 0, 5

		# Is the cart loading?
		cartLoading: -> !@$store.state.cart.hydrated

		# Get just the variant ids of the favorite products
		favoriteVariantIds: ->
			@$nonEmpty @products.map (product) -> product.variants?[0]?.id

		# Does the cart contain one of the favorite products? Intentionally leaving
		# subscriptions out since they don't trigger the quick-buys in-cart state.
		cartContainsFavorites: ->
			!!@$store.getters['cart/lineItems'].find (line) =>
				line.variant?.id in @favoriteVariantIds

	watch:

		# Load product data when favorited products change
		recentProductHandles:
			immediate: true
			handler: -> @fetchProducts()

	methods:

		# Load product data for favorited products
		fetchProducts: ->
			@loading = true
			@products = await @$storefront.getProductCards @recentProductHandles
			@products = await bundleRollupAll @products, { @$storefront }
			@loading = false
