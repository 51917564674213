###
Functions related to the capturing of salesforce marketing cloud query params
and then inclusion of those in conversion pixels.
###

# Deps
import { cookie } from 'library/services/helpers/cookie'
import { twoDecimals } from 'library/services/helpers/number'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import { onConsent, ONETRUST_CATEGORIES } from 'library/services/onetrust/await-consent'

# Settings
COOKIE_KEY = 'sfmc-query'
sfmcQueryKeys = [ 'sfmc_sub', 'e', 'j', 'l', 'jb', 'u', 'mid']

# Capture sfmc query params. This expects an object dictionary of all query
# params.
export captureSfmcQueryParams = (allQueryParams = {}) ->

	# filter the query params, here we are checking for specific
	# sfmc query keys present
	val = pickBy allQueryParams, (value, key) ->
		sfmcQueryKeys.includes key

	# Return if there is no value
	if isEmpty val then return

	# Store the cookie
	onConsent ONETRUST_CATEGORIES.FUNCTIONAL, ->
		cookie.set COOKIE_KEY, val, expires: 30 # Days

# Create the conversion pixel from data in the captured cookie
export popSfmcConversionPixelParams = ->

	# Check for smfc query cookie
	return unless val = cookie.get COOKIE_KEY
	params = JSON.parse val

	# Delete the cookie so we don't double convert
	cookie.remove COOKIE_KEY
	return params
