import { cookie } from 'library/services/helpers'
import { FAVORITES_KEY, GTM_EVENTS } from 'library/constants'
import axios from 'axios'

# The url for our pickup function
export favoritePickup =  "#{process.env.NUXT_APP_URL}/.netlify/functions/shopify-favoriting"

# Get favorites, including merging cookeis into a customer if found
export getFavorites = (customerId) ->

	# Get cookied favorites if they exist
	cookiedFavorites = getCookieFavorites()

	# If there is a customer ...
	if customerId

		# ... And there were cookied favorites, store them on the customer and
		# then delete the cookie and return it's value.  This scenario should only
		# exist when someone has been favoriting things and then later signs in to
		# an account.  In effect, we're migrating their cookied favorites to their
		# custmomer metadata.
		if cookiedFavorites?.length
			try
				await updateCustomerMetafields customerId, cookiedFavorites
				cookie.remove FAVORITES_KEY
				return cookiedFavorites
			catch e
				console.error e
				return []

		# ... Else there was no cookie, so use whatever we find on the customer
		try return await getCustomerFavorites customerId
		catch e
			console.error e
			return []

	# ... Else, since they're not a customer, return whatever we found in the
	# cookie
	return cookiedFavorites

# Get the favorites that are stored in either cookies or customer data
export getCookieFavorites = ->
	favorites = cookie.get FAVORITES_KEY
	if !favorites then return []
	else return JSON.parse favorites

# Get the favorites for a logged in customer
export getCustomerFavorites = (customerId) ->
	{ data } = await axios
		method: 'post'
		url: favoritePickup
		data:
			action: "GET"
			customerId: customerId
	if json = data?.favoriteProducts?.value
	then JSON.parse json else []

# Helper function for updating shopify metafields
updateCustomerMetafields = (customerId, products) ->
	await axios
		method: 'post'
		url: favoritePickup
		data:
			action: "POST"
			products: products
			customerId: customerId

# When favorites change, sync them to customer metafields or to a cookie
syncFavoritesToStorage = (store) ->
	store.watch ((state) -> state.favorites.products)
	, (products) ->

		# If we know who who the customer is, update their metafields don't bother
		# with cookies
		if customerId = store.state.customer.id
			try return updateCustomerMetafields customerId, products
			catch e then console.error e

		# Else, store favorites choices to cookie
		cookie.set FAVORITES_KEY, products, expires: 365

# Hydrate the favorites and update storage when the favorites change
export hydrateProductFavorites = (store) ->

	# When the customer is hydrated, load their favorites
	store.watch ((state) -> state.customer.hydrated)
	, ((customerHydrated) ->
		return unless customerHydrated

		# Load the user's favorites and hydrate vuex
		await store.dispatch 'favorites/fetch'

		# Start listenting to future changes to favorited products
		syncFavoritesToStorage store

		# Fire GTM event with initial hasFavorites state
		window.dataLayer.push
			event: GTM_EVENTS.FAVORITES_HYDRATED
			hasFavorites: store.getters['favorites/hasFavorites']

	), immediate: true
