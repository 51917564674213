



















export default

	props:
		count: Number | String

	computed:

		# Don't support more than 99
		countText: -> if @count > 99 then '99+' else @count

