




































import AccountState from '../account-state/account-state'
import AffixingHeader from 'library/components/layout/header/affixing-header'
import CartIcon from 'library/components/layout/cart-icon'
import FavoritesNavIcon from 'library/components/global/favorites/nav-icon'
import Hamburger from './hamburger'
import MenuFlyout from './menu-flyout'
import SiteLogo from 'library/components/layout/site-logo'
import dimensions from 'library/styles/vars/dimensions'

export default

	components: {
		AccountState
		AffixingHeader
		CartIcon
		FavoritesNavIcon
		Hamburger
		MenuFlyout
		SiteLogo
	}

	data: ->
		open: false
		height: parseInt dimensions['mobile-header-h']
		showDTCMenu:
			# Not going to show DTC menu on international sites atm
			if process.env.CMS_SITE in ['prod', 'uat', 'dev']
			then true
			else false

	watch: $route: (to, from) -> @open = false

	methods:

		handleClick: -> @open = !@open

