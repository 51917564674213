








import logo from '~/assets/images/clifbar-logo.svg'
export default
	data: -> { logo }

