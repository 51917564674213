










export default
	props:
		height: String
		block: Object

	computed: spacerHeight: -> @height || @block?.height || 'xl'

