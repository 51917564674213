

























import SmartNavLink from './smart-nav-link'
import LocaleSelector from 'library/components/global/locale-selector'
export default

	components: {
		SmartNavLink
		LocaleSelector
	}

	computed: navData: -> @$store.state.globals.secondaryMobileMenu.children

