# Deps
import throttle from 'lodash/throttle'
import breakpoints from 'library/styles/vars/breakpoints.json'

# Settings
tablet = parseInt breakpoints.tablet
navBreak = parseInt breakpoints['nav-break']

# Variables related to the general layout of the page
export state = ->

	# Viewport size
	viewport:
		width: null
		height: null

export mutations =

	setViewportDimensions: (state, dimensions) -> state.viewport = dimensions

export getters =

	# Return boolean only if viewport has been measued
	isDesktop: (state) ->
		if state.viewport.width then state.viewport.width >= tablet
	usingDesktopHeader: (state) ->
		if state.viewport.width then state.viewport.width >= navBreak
	isMobile: (state) ->
		if state.viewport.width then state.viewport.width < tablet
	usingMobileHeader: (state) ->
		if state.viewport.width then state.viewport.width < navBreak

# Shared method for adding listeners to update the store
export createListeners = (store) ->

	# Store the throttled window size
	updateDimensions = ->
		store.commit 'layout/setViewportDimensions',
			width: window.innerWidth
			height: window.innerHeight

	# DRY up setting window resize listeners and return a cleanup function
	onResize = (callback, throttleMs = 100) ->
		throttledCallback = throttle callback, throttleMs
		window.addEventListener 'resize', throttledCallback
		callback()
		return () -> window.removeEventListener 'resize', throttledCallback

	# Add initial listeners
	onResize updateDimensions

	# Return the resize helper
	return { onResize }
