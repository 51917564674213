# Deps
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
import axios from 'axios'

# Reduce a price by the subscription discount and fix to 2 decimals to prevent
# JS rounding weirdness.
export applySubscriptionDiscount = (price) ->
	calculate = (value) -> (value * (1 - SUBSCRIPTION_DISCOUNT_PERCENTAGE / 100)).toFixed(2)
	if price.hasOwnProperty 'amount' # Check if the value was priceV2 instance
	then amount: calculate price.amount
	else calculate price

# Make requests via Axios to the Netlify function that operates as a helper
# proxy for talking to the Ordergroove API
export ordergrooveApi = (method, accessToken, payload = null) ->
	url = "#{process.env.NUXT_APP_URL}/.netlify/functions/ordergroove-api-helper"
	{ data } = await axios.post url, payload,
		params: { method, accessToken }
	return data
