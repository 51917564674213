

























import hasPricespiderBtn from 'library/mixins/has-pricespider-btn'

export default

	mixins: [ hasPricespiderBtn ]

	props: block: Object

	computed:

		# Make root clases
		classes: -> [
			'text-' + @block.align
		]

		# Default to being an arrwy
		buttons: -> @block.wysiwygButtons || []

	mounted: ->
		@resizeVideos()

	methods:
		# https://css-tricks.com/fluid-width-video/
		# Resize embedded videos
		resizeVideos: ->

			# List of Video Vendors embeds you want to support
			players = [
				'iframe[src*="youtube.com"]'
				'iframe[src*="vimeo.com"]'
			]
			# Select videos
			fitVids = document.querySelectorAll(players.join(','))

			# If there are videos on the page...
			return unless fitVids.length

			# Loop through videos
			for fitVid in fitVids

				# Get Video Information
				width = fitVid.getAttribute('width')
				height = fitVid.getAttribute('height')
				aspectRatio = height / width
				parentDiv = fitVid.parentNode

				# Wrap it in a DIV
				div = document.createElement('div')
				div.className = 'video-wrapper'
				div.style.paddingBottom = aspectRatio * 100 + '%'
				parentDiv.insertBefore div, fitVid
				fitVid.remove()
				div.appendChild fitVid

				# Clear height/width from fitVid
				fitVid.removeAttribute 'height'
				fitVid.removeAttribute 'width'


