###
Helpers for interacting with cart APIs

Converted from Checkout API to Storefront API Feb '23
https://app.asana.com/0/0/1203824184893606/f
###
import mapValues from 'lodash/mapValues'
import isPlainObject from 'lodash/isPlainObject'
import { makeShopifyGid } from 'library/services/helpers'
import fetchQuery from 'library/queries/shopify/cart/fetch.gql'
import createQuery from 'library/queries/shopify/cart/create.gql'
import addQuery from 'library/queries/shopify/cart/add.gql'
import updateQuery from 'library/queries/shopify/cart/update.gql'
import updateCartAttributesQuery from 'library/queries/shopify/cart/update-cart-attributes.gql'
import deleteQuery from 'library/queries/shopify/cart/delete.gql'
# import discountsQuery from 'library/queries/shopify/cart/discounts.gql'
# import linkCustomerQuery from 'library/queries/shopify/cart/link-customer.gql'
import { execute } from './storefront'

# Throw errors if found in the response, else map the checkout object
handleCheckoutMutation = ({ mutation: response }) ->
	if response.userErrors?.length
	then throw JSON.stringify response.userErrors
	return response.cart

# Lookup a checkout
export fetch = (id) ->
	{ cart } = await execute
		query: fetchQuery
		variables: { id }
	return cart

# Remove lines with invalid variants
cleanCart = (cart) ->
	return null unless cart
	lines = cart.lineItems
	for lineItem in lines
		unless lineItem.variant
		then cart = await updateQuantity cart.id, lineItem.id, 0
	return cart

# Create a new checkout
export create = ->
	handleCheckoutMutation await execute
		query: createQuery
		variables: input: {}

# Add an item to the checkout
export addVariant = ({
	cartId, variantId, quantity, sellingPlanId, attributes = {}
}) ->
	handleCheckoutMutation await execute
		query: addQuery
		variables:
			cartId: cartId
			lines: [
				merchandiseId: makeShopifyGid variantId, 'ProductVariant'
				quantity: quantity
				sellingPlanId: makeShopifyGid sellingPlanId, 'SellingPlan'
				attributes: { key, value } for key, value of attributes
			]

# Add several lines, helping with the migration of old checkout API
# with specific quantity for each line
# Lines are expected to have the following structure:
# {
# 	variantId
# 	quantity
# 	sellingPlanId
# }
export addLines = ({ cartId, lines }) ->
	handleCheckoutMutation await execute
		query: addQuery
		variables:
			cartId: cartId
			lines: lines.map (line) ->
				merchandiseId: makeShopifyGid line.variantId, 'ProductVariant'
				quantity: line.quantity
				sellingPlanId: makeShopifyGid line.sellingPlanId, 'SellingPlan'
				attributes: { key, value } for key, value of line.attributes

# Update a line item
export updateLine = ({ cartId, lineId, quantity, sellingPlanId }) ->
	updateLines { cartId, lines: [{ lineId, quantity, sellingPlanId }] }

# Update a line item with specific quantity for each line
# Lines are expected to have the following structure:
# {
# 	lineId
# 	quantity
# 	sellingPlanId
# 	attributes (in the Shopify array style rather than a hash)
# }
export updateLines = ({ cartId, lines }) ->
	handleCheckoutMutation await execute
		query: updateQuery
		variables:
			cartId: cartId
			lines: lines.map (line) ->
				id: line.lineId
				quantity: line.quantity
				sellingPlanId: line.sellingPlanId
				attributes: line.attributes

# Write cart attributes
export updateCartAttributes = ({ cartId, attributes }) ->
	handleCheckoutMutation await execute
		query: updateCartAttributesQuery
		variables: { cartId, attributes }

# Delete a line item
export deleteLine = ({ cartId, lineId }) -> await deleteLines {
		cartId
		lineIds: [ lineId ]
	}

# Delete multiple line items
export deleteLines = ({ cartId, lineIds }) ->
	handleCheckoutMutation await execute
		query: deleteQuery
		variables:
			cartId: cartId
			lines: lineIds
