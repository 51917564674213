












export default

	props:

		# plus or minus
		type:
			type: String
			default: 'plus'

		# standard or chevron
		theme:
			type: String
			default: 'standard'

	computed:
		classes: -> [
			"theme-#{@theme}"
			"type-#{@type}"
		]

		# Make the aria label
		label: ->
			if @type == 'plus'
			then 'Increase quantity'
			else 'Decrease quantity'

	data: ->
		loaded: false

