














































import AddToCartBtn from './add-to-cart-btn'
import SubscriptionOptions from '../subscribing/subscription-options'
import 'vue-height-tween-transition/index.css'
import HeightTween from 'vue-height-tween-transition'
import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'
import subscribableProductMixin from 'library/services/shopify/subscribing/subscribable-product-mixin'

export default
	name: 'standardPurchase'

	components: { AddToCartBtn, SubscriptionOptions, HeightTween }

	mixins: [ subscribableProductMixin ]

	inject:
		onQuickShopModal: default: false

	props:
		product: Object
		variant: Object
		parentAddToCart: Function
		quantity: Number
		subscribeable:
			type: Boolean
			default: true # For backwards compatability

	data: ->
		adding: false

	computed:
		currentVariant: -> @variant

		# Button tex
		btnLabel: -> if @variant.available then 'Add to Cart' else 'Unavailable'

		component: ->
			if @onQuickShopModal
			then GTM_COMPONENTS.QUICK_SHOP_MODAL
			else GTM_COMPONENTS.PRODUCT_CARD

		# Make the add to card payload
		payload: -> {
			variantId: @$getShopifyId @variant.id
			quantity: @quantity || 1
			@component
		}

		isBundle: -> !!@product?.bundleVariants?.length

		isBundledVariant: -> !!@variant?.isBundle

		bundlePayload: ->
			# Source of the bundling meta data can be one of two options:
			# - currentVariant is the selected variant is a bundle that includes the
			#		product of the current quickshop modal
			# - product is when the current quickshop modal is a bundle itself
			source = if @variant?.isBundle then @variant else @product
			return {
				bundleSku: @variant.sku
				bundleVariants: source.bundleVariants.map (variant) =>
					variantId: if variant.id then variant.id else variant.variantId
					count: variant.count
				quantity: @quantity || 1
				@component
			}

		# Add subscription fields to the bundle payload
		bundleSubscriptionPayload: ->
			{
				...@bundlePayload
				sellingPlanId: @currentSellingPlanId
			}

		# Add subscription fields to the basic item payload
		subscriptionPayload: -> {
			...@payload
			sellingPlanId: @currentSellingPlanId
		}

		purchaseText: -> "Purchase<br>Now"

		subscribeText: -> "Subscribe & <br>Save #{@subscriptionDiscount}"

	methods:

		# Add the variant to the cart
		addToCart: ->
			@adding = true

			switch
				when @isSubscribing and (@isBundle or @isBundledVariant)
					await @$store.dispatch 'cart/addBundleSubscription', @bundleSubscriptionPayload
					break
				when @isSubscribing
					await @$store.dispatch 'cart/addSubscription', @subscriptionPayload
					break
				when @isBundle or @isBundledVariant
					await @$store.dispatch 'cart/addBundle', @bundlePayload
					break
				else await @$store.dispatch 'cart/addItem', @payload

			@$emit 'lineAdded', {
				@variant
				@quantity
				sellingPlanAllocation: @currentSellingPlanAllocation if @isSubscribing
			}

			@$openCart(@$el) unless @onQuickShopModal

			@adding = false

		onSuccess: () -> @$emit("close")
