


















import ColumnItem from './_column-item'

export default
	name: 'column'

	components: {
		ColumnItem
	}

	props:
		column: Object

	computed:
		styles: ->
			backgroundColor: @column.backgroundColor

