# State related to loading

export state = ->
	pageLoading: false
	dataLoading: false

export mutations =

	# Loading as part of page transition
	setPageLoading: (state, bool) -> state.pageLoading = bool

	# Just data loading
	setDataLoading: (state, bool) -> state.dataLoading = bool

export actions =

	# Helper to set data loading
	while: ({ commit }, asyncCallback) ->
		commit 'setDataLoading', true
		await asyncCallback()
		commit 'setDataLoading', false

export getters =

	# Is any kind of loaidng hapening
	isLoading: (state) -> state.pageLoading or state.dataLoading
