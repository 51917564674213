import memoize from 'lodash/memoize'
import { onConsent } from 'library/services/onetrust/await-consent'

# Re-export categories for easier consumption.  This exposes globally in Vue
# at like @$ONETRUST_CATEGORIES
export { ONETRUST_CATEGORIES } from 'library/services/onetrust/await-consent'

# Create a script tag and watch for it to load
export loadScript = memoize (src, {
	defer = false
	async = false
	memoize = true
	consent = null
} = {}) ->
	return new Promise (resolve, reject) ->

		# Actually create the script element
		createScript = ->
			script = document.createElement('script')
			script.onload = resolve
			script.onerror = reject
			script.src = src
			script.defer = true if defer
			script.async = true if async
			document.head.appendChild script

		# If on a OneTrust enabled site, load the script using OneTrust
		if process.env.CMS_SITE in ['prod', 'uat', 'dev']
			if consent # A cooke category id ID
			then return onConsent consent, createScript
			else console.warn 'loadScript() requires a consent value'

		# Else, add the script tag manually
		createScript()

# Customize the memoize cache to support disabling
, (src, options) ->
	if options?.memoize == false
	then return src + Date.now() # Cache bust
	else return src
