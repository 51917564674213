










export default

	computed:

		# Shorthands
		loading: -> @$store.getters['loading/isLoading']

