###
Keeps tab focus inside of a modal or popup
###
export default

	data: ->
		# all general focusable elements
		focusableElements: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), .smart-nav-link'
		focusModal: null
		firstFocusableElement: null
		lastFocusableElement: null

	methods:

		# Kick off focus
		initFocus: ->
			@$nextTick ->
				@focusModal = @$el
				# Give 1s buffer for modal to load its slot
				@$wait 1000, => @initModalFocus()

		# init the focus modal and set up the elements to listen to
		initModalFocus: ->
			# In case there's a re-initialization
			@removeEventListeners()
			# Get the elements to determine first and last
			focusableContent = @focusModal.querySelectorAll(@focusableElements)
			@firstFocusableElement = focusableContent[0]
			@lastFocusableElement = focusableContent[focusableContent.length - 1]
			# Set event listener and lock focus on the first element
			@setListeners()
			@firstFocusableElement.focus()

		# Set the listener for tab press
		setListeners: ->
			document.addEventListener 'keydown', (event) =>
				tabHit = event.key == 'Tab'
				return unless tabHit

				if event.shiftKey
					if document.activeElement == @firstFocusableElement
						event.preventDefault()
						@lastFocusableElement.focus()
				else
					if document.activeElement == @lastFocusableElement
						event.preventDefault()
						@firstFocusableElement.focus()

		removeEventListeners: ->
			document.removeEventListener 'keydown', @tabElements

	destroyed: -> @removeEventListeners()
