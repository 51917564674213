# Choose the endpoint to submit CDIL forms to
export getCdilOrigin = -> switch

	# Override for local dev
	when process.env.NETLIFY_FUNCTIONS_URL
		return process.env.NETLIFY_FUNCTIONS_URL

	# Dev (not supportet ATM)
	when process.env.APP_ENV == 'dev'
		throw 'Not supported yet'

	# UAT
	when process.env.APP_ENV == 'uat'
		return 'https://cdil-api---uat-195f76fc328fa4238d49.o2.myshopify.dev'

	# Prod
	else
		throw "https://cdil-api---prod-20ae8b20ad1408027968.o2.myshopify.dev"
