











import NavColumnWrapper from './columns/_nav-column-wrapper'

export default

	components: {
		NavColumnWrapper
	}

	provide: -> menuTitle: @item.title

	name: 'navigation-dropdown'

	props:
		item: Object

	# Listener for accessibility escape dropdown
	mounted: ->	document.addEventListener 'keydown', @checkEscape

	# Cleanup Listener
	destroyed: ->	document.removeEventListener 'keydown', @checkEscape

	methods:
		checkEscape: (e) ->
			if e.key == 'Escape' or e.keyCode == 27
			then @$emit 'escaped'

