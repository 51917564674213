











import DesktopHeader from 'library/components/layout/header/desktop/desktop-header'
import MobileHeader from 'library/components/layout/header/mobile/index'
import Loader from './loader'

export default

	components: {
		DesktopHeader
		MobileHeader
		Loader
	}

