


































import Modal from '../modal'
import getShopifyProduct from 'library/queries/shopify/product.gql'
import getCraftProduct from 'library/queries/craft/product.gql'
import ProductDescription from './product-description'
import QuickCart from './quick-cart'
import Placeholder from 'library/components/global/placeholder'
import { bundleRollup } from 'library/services/shopify/bundling/rollupProduct'
import { getPageDataFor } from 'library/services/static-data'

export default
	name: 'QuickShop'

	provide:
		onQuickShopModal: true

	components: {
		Modal
		ProductDescription
		QuickCart
		Placeholder
	}

	props:
		id: String
		handle: String
		initializer: null

	data: ->
		product: null
		loading: true
		line: # Will store what was added to the cart
			variant: null
			quantity: null
			sellingPlanAllocation: null

	beforeMount: -> @getProductData()

	computed:

		# Helper accessor for determining if something is added to the cart
		addedToCart: -> @line.quantity > 0

	methods:

		closeModal: -> @$refs.quickShopModal.close()

		updateProduct: ({id}) ->
			await @getProductData id

		getProductData: (id = @id) ->
			@loading = true

			# Get Shopify Data
			{ node } = await @$storefront.execute
				query: getShopifyProduct
				variables: id: @$makeShopifyGid id

			# Pass the product to the bundle rollup
			product = await bundleRollup node, { @$storefront }

			@product = {
				...product
				id: product.id
				title: product.title
				images: product.images
				variants: product.variants
				handle: product.handle
			}

			# Get Craft Data for the parent image assets
			craftProduct = await getPageDataFor "products/#{@product.handle}", {
				@$craft
				forceIfProd: true
			}

			flavorAssets = craftProduct?.pdpImageSliderAssets || []

			parentAssets =
				if craftProduct?.showProductLineAssets and !!craftProduct?.parent?.pdpImageSliderAssets?.length
					craftProduct?.parent?.pdpImageSliderAssets
				else []

			@product.images = [
				...@product.images
				...flavorAssets
				...parentAssets
			]

			@loading = false
