







































































import TapButton from 'library/components/global/buttons/tap-button'

isInteger = (val) -> !!String(val).match /^\d+$/

export default

	components: { TapButton }

	props:
		size:
			type: String
			validator: (val) -> val in ['normal', 'small']
		min:
			type: Number
			default: 1
		max:
			type: Number
			default: 99
		title:
			type: String
			required: true # We really need this for ADA labels
		value:
			type: Number | String
			validator: isInteger
		forceMobile:
			type: Boolean

	data: -> state: parseInt @value

	watch:

		# Limit the allowed values
		state: ->
			@state = @applyBounds @state
			@emitChange()

		# Our quantity can get updated by other factors like unchecking the
		# subscription opt in
		value: ->
			return if @value == @state
			@state = @value

	methods:

		# Incremenet the quantity
		increase: -> @state++

		# Decrement the quantity, down to 1
		decrease: -> @state--

		# Apply limits to the val
		applyBounds: (val) -> Math.min @max, Math.max @min, val

		# Handle direct input
		onBlur: ->
			@state = 1 unless isInteger @state
			@emitChange()

		# Trigger parent v-model change
		emitChange: -> @$emit 'input', parseInt @state

	computed:
		ariaLabel: -> @title + ' Quantity'

		minLabel: -> @state == @min ? 'disabled decrease quantity' : 'decrease quantity'
		maxLabel: -> @state == @max ? 'disabled increase quantity' : 'increase quantity'

		parentClass: -> [
			@size
			"force-mobile" if @forceMobile
		]

		desktopControls: ->
			if @forceMobile
			then 'hide'
			else 'hide-mobile'

		mobileControls: -> [
			if !@forceMobile then 'hide-desktop'
		]
