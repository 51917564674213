







export default
	name: 'divider'

	props:
		data: Object

