





















import Modal from 'library/components/global/modals/modal'


export default
	name: 'UnfavoriteModal'

	components: {
		Modal
	}

	props:
		handle: String
		component: String
		initializer: null

	methods:

		closeModal: -> @$refs.unfavoriteModal.close()

		removeFavorite: ->
			await @$store.dispatch 'favorites/toggle', @handle

			# Update GTM
			@$gtm.push
				event: @$GTM_EVENTS.UNFAVORITE
				component: @component
				productHandle: @handle
				hasFavorites: @$store.getters['favorites/hasFavorites']

			@$wait 100, @closeModal
