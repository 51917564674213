import _throttle from 'lodash/throttle'
import _debounce from 'lodash/debounce'

# Wait a tick then execute callback
export defer = (cb) -> setTimeout cb, 0

# Wait a tick then execute callback
export wait = (ms, cb) -> setTimeout cb, ms

# Throttle with order of arguments reverse
export throttle = (delay, cb) -> _throttle cb, delay

# Debounce with order of arguments reverse
export debounce = (delay, cb) -> _debounce cb, delay
