import memoize from 'lodash/memoize'
import getCollection from 'library/queries/craft/collection.gql'
import getProduct from 'library/queries/craft/product.gql'
import { shouldUseStaticData } from './helpers'
import axios from 'axios'

# Get the page extras for an arbitrary URL
export getPageDataFor = memoize (path, {
	$craft,              # The Craft adapter
	forceIfProd = false, # Use static data on Nuxt if prod environment
}) ->
	try

		# Get the statically generated data for the page which is assumed to
		# live at the same page.
		if shouldUseStaticData() or
			(forceIfProd and process.env.APP_ENV == 'prod')
		then fetchStaticData path

		# Otherwise, query the Craft API directly, deciding which query to use
		# based on the first segment of the URL
		else queryCraft path, { $craft }

	# If the error is a 404, don't make it a fatal error
	catch error then switch error.response?.status
		when 404 then return
		else throw error

# Get data for the path from statically built files at Netlify
fetchStaticData = (path) ->

	# Root collections and products URLs get data from the all collection
	if path in ['collections', 'products'] then path += '/all'

	# Make the request
	dataUrl = "#{process.env.NUXT_APP_URL}/data/#{path}.json"
	response = await axios.get dataUrl
	return response.data

# Query Craft for data live
queryCraft = (path, { $craft }) ->
	parts = path.split '/'
	type = parts[0]
	slug = parts[1]
	switch type
		when 'collections'
			if slug then $craft.getEntry variables: { slug }, query: getCollection
			else $craft.getEntry variables: { slug: 'all' }, query: getCollection
		when 'products'
			if slug then $craft.getEntry variables: { slug }, query: getProduct
			else $craft.getEntry variables: { slug: 'all' }, query: getCollection
