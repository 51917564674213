import atob from 'atob-lite'

# Get the id from a Shopify gid:// style id.  This strips everything but the
# last part of the string.  So gid://shopify/ProductVariant/34641879105581
# becomes 34641879105581
# https://regex101.com/r/3FIplL/3
export getShopifyId = (id) ->
	return unless id

	# Convert the id to a string (it may be an integer number depending on the
	# source) so that comparisons don't fail because of type differences.
	id = String(id)

	# Already a simple ID
	return id if id.match /^\d+$/

	# De-base64. This should only be required when migrating cart ids that were
	# stored in a cookie, AKA client-side pre Storefront API version 2022-04.
	try
		id = atob(id) unless id.match /^gid:\/\//
		matches = id.match /\/([^\/?]+)(?:\?.+)?$/
		return matches[1].toString() if matches

	# If there is an issue, return the ID
	catch then return id

# Create the base64 shopify ID from a product ID. This is primarily used on
# PDP's to create ID's for gql queries.
# https://regex101.com/r/LTBaAK/2
export makeShopifyGid = (id, type = 'Product') ->
	return unless id
	return id unless String(id).match(/^\d+$/) # Only numbers
	"gid://shopify/#{type}/#{id}"

