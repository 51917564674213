###
After PTAH finishes scrolling, clear the anchor from the URL so the same link
can be clicked again to trigger scroll. I tried using vanilla JS
(history.replace) for this rather than than router.replace but successive
clicks don't trigger scrolling despite the route updating 🤷‍♂️
###
export default ({ app: { router }, route, store }) ->
	store.subscribe ({ type }) ->
		return unless type == 'ptah/stopScroll'
		return unless route.hash
		router.replace hash: ''
