import Column from './column'
import MenuTout from './menu-tout'

export default
	functional: true
	name: 'NavColumnWrapper'

	props: column: Object

	# Render the appropriate component
	render: (create, { props }) ->
		if props.column.headline?
		then create MenuTout, { props }
		else create Column, { props }

