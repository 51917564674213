























import { SEARCH_CATEGORIES } from 'library/constants'
export default

	props:
		block: Object
		location: String

	data: -> term: ''

	computed:
		ariaLabel: -> @block?.placeholder || @$t('search.search_box')

	methods:

		onSubmit: ->

			# GA testing to see if the search box that is at the bottom of the
			# contact-us page is useful for searches. Default to "site".
			search_category = if @$route.path == '/contact-us' and
				@location != 'header'
			then SEARCH_CATEGORIES.CONTACT
			else SEARCH_CATEGORIES.SITE

			# We need to check if the search is being used on shopify, if so
			# create a url to swap to, if not make a nuxt route push.
			if process.env.IS_NUXT
			then @$router.push
				path: '/search'
				query:
					query: @term.trim()
					search_category: search_category
			else window.location.href = @$makeNuxtUrl '/search?query=' +
				@term.trim() + "&search_category=" + search_category
