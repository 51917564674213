###
Add or remove automatic cart items based on their conditions
###
import Vue from 'vue'
import { execute } from 'library/services/shopify/storefront'
import { getShopifyId } from 'library/services/helpers'
import ChangeNotification from 'shopify-theme/components/checkout/change-notification'

# Add all listeners and behaviors
export applyAutomaticCartItems = (store) ->

	############################################################################
	# Actions
	############################################################################

	# Add the gift product to the cart
	addItemToCart = (handle) ->

		# Get the gift variant
		return unless variant = await getPrimaryVariant handle
		return unless variant.availableForSale

		# If the product is already in the cart, then return to prevent duplicates
		return if !!giftLineItem variant

		# If the item was previosuly hidden, reveal it. Else, prompt user to reload.
		if el = getCheckoutLineItemElement variant
		then el.style.display = ''
		else if isCheckout()
		then appendChangeNotification()

		# Add to cart
		await store.dispatch 'cart/addItem', {
			quantity: 1
			variantId: getShopifyId variant.id
		}

	# Remove the gift product from the cart
	removeItemFromCart = (handle) ->

		# Validate the handle can be resolved
		return unless variant = await getPrimaryVariant handle
		return unless lineItem = giftLineItem variant

		# Hide the line item during checkout
		if el = getCheckoutLineItemElement variant
		then el.style.display = 'none'

		# Remove from cart
		store.dispatch 'cart/updateItem', {
			lineId: lineItem.id
			quantity: 0
		}

	############################################################################
	# Helpers
	############################################################################

	# Get the variant id of a product if it's available to sell
	getPrimaryVariant = (handle) ->
		{ product } = await execute
			variables: { handle }
			query: '''
				query getProductVariants($handle:String!) {
					product: productByHandle(handle:$handle) {
						id
						variants(first:1) {
							edges {
								node {
									id
									availableForSale
								}
							}
						}
					}
				}
			'''
		return product?.variants[0]

	# Get the lineItem of the gift bar (which may not exist)
	giftLineItem = (variant) ->
		store.state.cart.lineItems.find (lineItem) ->
			lineItem.variant?.id == variant.id

	# Get the line item row during checkout
	getCheckoutLineItemElement = (variant) ->
		return unless isCheckout()
		selector = "[data-variant-id='#{getShopifyId(variant.id)}']"
		document.querySelector selector

	# Are we in the middle of checkout
	isCheckout = -> window?.location.href.includes '/checkouts/'

	# Add the change notification element to the list
	appendChangeNotification = ->
		lineItemsSelector = "[data-order-summary-section='line-items']"
		return unless parent = document.querySelector lineItemsSelector
		vm = new (Vue.extend ChangeNotification)
		vm.$mount()
		parent.appendChild vm.$el

	# Get the active conditions, based on current time
	getActiveConditions = ->
		now = new Date
		(store.state.globals.automaticCartItems?.conditions || [])
		.filter (condition) -> switch
			when condition.startTime and now < new Date condition.startTime
				return false
			when condition.endTime and now >= new Date condition.endTime
				return false
			else true

	############################################################################
	# Listeners
	############################################################################

	# On shopify checkout pages, we need to hook into the 'page:change' event
	# to re-fetch the cart in case the user entered a discount code that moved
	# them below the threshold
	if isCheckout()
	then document.addEventListener 'page:change', ->
		window.$store.dispatch 'cart/fetch'

	# Wait until the cart is hydrated to start watching for subtotal changes.
	# This is necessary because the cart is hydrated in two stages because the
	# subscriptions come later.
	store.watch(
		(state) -> state.cart.hydrated
		(hydrated) -> watchSubtotalChange() if hydrated
		immediate: true
	)

	# We're watching the subtotal of the user's checkout so that we can add or
	# remove the gift product based on a threshold for cart subtotal price.
	watchSubtotalChange = -> store.watch(
		(state, getters) -> getters['cart/subtotal']
		(subtotal) -> onSubtotalChange parseFloat(subtotal)
		immediate: true
	)

	# Loop through the conditions and decide whether to add or remove that item
	onSubtotalChange = (subtotal) ->
		for { productHandle, subtotalThreshold } in getActiveConditions()
			if subtotal >= subtotalThreshold
			then addItemToCart productHandle
			else removeItemFromCart productHandle
