










export default
	props: link: Object

