import throttle from 'lodash/throttle'
import dimensions from 'library/styles/vars/dimensions.json'
import { createListeners } from 'library/store/layout'
export default ({ app, store }, inject) ->

	# Keep VueX up to date
	{ onResize } = createListeners store
	inject 'onResize', onResize

	# Update the offset of the anchor scroller
	store.watch(
		(state) -> state.layout.viewport.width,
		(winW) ->
			headerH = if (window.innerWidth >= 	768)
			then dimensions['header-h']
			else dimensions['mobile-header-h']
			app.$setVerticalOffset parseInt(headerH, 10) * -1
		, immediate: true
	)

