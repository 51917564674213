










import { getAssetObject } from 'library/components/global/visual/craft-visual'
import { makeImgixUrl } from 'library/services/helpers'
export default

	props:
		image: Object|Array
		side: String # 'top' or 'bottom'

	computed:

		# Get the image object
		asset: -> getAssetObject @image

		imgixAsset: -> makeImgixUrl @asset

		# Style the object
		styles: ->
			height: @asset.height + 'px'
			backgroundImage: "url('#{ @imgixAsset }')"

		# Add classes
		classes: -> @side

