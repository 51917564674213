























export default

	props: sku: String | Number

	computed:
		wtbLink: -> "/" + (@$t('routes.where_to_buy') || 'where-to-buy')

