



















export default
	name: 'LargeLink'

	props: link: Object

	methods: forward: -> @$emit 'forward'

