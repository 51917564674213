





















import { applySubscriptionDiscount } from 'library/services/ordergroove/helpers'
export default

	props:
		variant: Object
		sellingPlanAllocation: Object
		stacked: Boolean # Show discount on it's own line
		freeIfZero: Boolean

		# Support explicitly price values
		price: String | Number | Object
		compareAtPrice: String | Number | Object
		finalValue: Boolean # Don't calculate additional discounts

	computed:

		# Add classes to root element
		classes: -> {
			discounted: @hasDiscount
			@stacked
		}

		# Get the price values from explicit or variant fields, falling back to
		# "0" if both source values are false-y, which includes "0", like for
		# free products.
		priceValue: ->
			@$flattenMoney @price || @variant?.price || 0
		compareAtPriceValue: ->
			@$flattenMoney @compareAtPrice || @variant?.compareAtPrice || 0

		# If there is no discounted price, this is the set price.  Otherwise,
		# this is the price before discount
		originalPrice: -> @compareAtPriceValue || @priceValue

		# Return the actual price of the product
		effectivePrice: ->

			# If the passed in value was final, don't apply extra discount
			# calculations
			return @priceValue if @finalValue

			# Get the lowest of discount effecting the price
			return Math.min(
				@priceValue
				@evDiscountedPrice || Infinity
				@ogDiscountedPrice || Infinity
			)

		# Expert voice discounted price, if applicable
		evDiscountedPrice: ->
			if evDiscount = @$store.getters['cart/evDiscount']
			then @$flattenMoney(@priceValue) * (1 - evDiscount / 100)

		# Ordergroove discounted price, if applicable
		ogDiscountedPrice: ->
			if !!@sellingPlanAllocation
			then applySubscriptionDiscount @priceValue

		# There is a discount if the calculated price is differnet than the
		# original price
		hasDiscount: -> @originalPrice - @effectivePrice > 0

		# Show "free" label
		displayFree: -> parseInt(@priceValue) == 0 and @freeIfZero

