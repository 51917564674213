import Cookies from 'js-cookie'
import { currentApexDomain } from './url'

# Helpers for interacting with cookies that get shared across domains
export cookie = new class SharedCookie

	# Make the env-specifc key suffix.  Falling back to APP_ENV for uses like
	# custom-web-pixel which don't have CMS_SITE.  I sorta thing that APP_ENV
	# would have made more sense for this but I don't want to risk a regression
	# in changing it ATM.
	constructor: ->
		env = process.env.CMS_SITE || process.env.APP_ENV
		@suffix = if env == 'prod' then '' else "-#{env}"

	# Set a cookie that can be shared across subdomains
	set: (key, value, options = {}) ->
		Cookies.set key + @suffix, value, {
			domain: currentApexDomain()
			secure: location.hostname not in ['localhost', 'dev-www.clifbar.com']
			sameSite: 'strict'
			...options
		}

	# Get a cookie
	get: (key) -> Cookies.get key + @suffix

	# Remove a cookie
	remove: (key) -> Cookies.remove key + @suffix, domain: currentApexDomain()

