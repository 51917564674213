import { render, staticRenderFns } from "./quick-shop-purchase.vue?vue&type=template&id=207a7d9a&scoped=true&lang=pug&"
import script from "./quick-shop-purchase.vue?vue&type=script&lang=coffee&"
export * from "./quick-shop-purchase.vue?vue&type=script&lang=coffee&"
import style0 from "./quick-shop-purchase.vue?vue&type=style&index=0&id=207a7d9a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207a7d9a",
  null
  
)

export default component.exports