// Cart attribute keys for storing expert voice values
export const CART_ID_ATTRIBUTE_KEY = 'cartId'
export const EV_CODE_CART_ATTRIBUTE_KEY = 'expertVoiceCode'
export const EV_AUTH_CART_ATTRIBUTE_KEY = 'expertVoiceAuth'

// The default code of expert voice pro referrals
export const EV_DEFAULT_CODE = 'expertvoicepro'

// Mapping of user group code to discount
export const EV_DISCOUNT_PERCENTAGES = {
	[EV_DEFAULT_CODE]: 30
}
