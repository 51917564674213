











import { GTM_COMPONENTS } from 'library/constants'
import { fireCarouselVideoEvent } from 'library/services/gtm/general-events'
import videoMetrics from 'library/mixins/video-metrics'

export default

	mixins: [ videoMetrics ]

	props:
		src: String
		alt: String

	data: ->
		playTime: 0

	methods:
		onPlay: -> @playTime = @$refs.embeddedVideo.currentTime
		onPause: -> @fireEvent()
			
		fireEvent: ->
			# Calculate playTime and round 2 decimals
			@playTime = Math.round(((@$refs.embeddedVideo?.currentTime || 0) - @playTime) * 100) / 100
			# If playTime is negative (mean that player is seeked back) then return immediately
			# This will reset the new playTime via @onPlay()
			# Otherwise, fire event
			return unless @playTime > 0
			fireCarouselVideoEvent(
				GTM_COMPONENTS.PRODUCT_CAROUSEL
				@getVideoTitle()
				@playTime
				@getVideoViewedPercent()
			)

	mounted: ->
		@$refs.embeddedVideo.addEventListener 'play', @onPlay
		@$refs.embeddedVideo.addEventListener 'pause', @onPause
		window.addEventListener 'beforeunload', @fireEvent

