













export default
	name: 'header-link'

	props:
		item: Object

