# Deps
import Vue from 'vue'
import { formatMoney, smallDate } from 'library/services/helpers'

# JS with side-effects to apply globally
import 'focus-visible'

###
# Global components
###

# Btn instance
import Btn from 'library/components/global/buttons/btn'
Vue.component 'btn', Btn

# Vue Visual
import Visual from 'vue-visual'
import CraftVisual from 'library/components/global/visual/craft-visual'
import ResponsiveVisual from 'library/components/global/visual/responsive-visual'
import ShopifyVisual from 'library/components/global/visual/shopify-visual'
Vue.component 'visual', Visual
Vue.component 'craft-visual', CraftVisual
Vue.component 'responsive-visual', ResponsiveVisual
Vue.component 'shopify-visual', ShopifyVisual
import 'vue-visual/index.css'

# Product card
import ProductCard from 'library/components/global/product-card/product-card'
Vue.component 'product-card', ProductCard

# Helper for explicit links
import SmartLink from 'library/components/global/smart-link'
Vue.component 'smart-link', SmartLink

# WYSWIYG HTML renderer
import Wysiwyg from 'library/components/global/wysiwyg'
Vue.component 'wysiwyg', Wysiwyg

# Lazy Hydrate
import LazyHydrate from 'vue-lazy-hydration'
Vue.component 'lazy-hydrate', LazyHydrate

###
Global filters
###

Vue.filter 'money', formatMoney
Vue.filter 'smallDate', smallDate

###
# Injections
###
export default ({}, inject) ->

	# Fake the Vuetify config to fix a-la-carte support
	inject 'vuetify',
		rtl: false
		theme: disable: true
		icons: iconfont: 'icomoon'
