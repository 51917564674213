import { mountOnBody } from 'library/services/helpers'
import VideoModal from 'library/components/global/modals/video'

export default
	methods:
		# Play video in a modal if one was specified
		onClickIfHasVideoModal: (event, { videoModal, href: videoUrl }) ->
			return unless videoModal
			event.preventDefault()
			await mountOnBody VideoModal,
				parent: this
				propsData: { videoUrl }
