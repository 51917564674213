
# Nl2br function, https://stackoverflow.com/a/784547/59160
export nl2br = (str) -> str.replace /(?:\r\n|\r|\n)/g, '<br>'

# Uppercase first letter
import upperFirst from 'lodash/upperFirst'
export ucFirst = upperFirst

# Make slugs
import kebabCase from 'lodash/kebabCase'
export kebab = kebabCase

# Handleize a given string
export handleize = (val) ->
	return val.toLowerCase().replace /\s+/g, '-'

# Remove env constraint and prefix from an index name, like:
# craft_fr_CA_events -> events
export algoliaIndexSlug = (indexName) -> indexName.match(/_([^_]+$)/)[1]

