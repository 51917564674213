












import BlockList from '~/components/global/blocks/block-list'
import pageMixin from '~/mixins/page-mixin'
import getTower from 'library/queries/craft/tower.gql'
export default

	mixins: [ pageMixin ]

	components: { BlockList }

	props: ['error']

	data: -> page: null

	fetch: ->

		# If the error is a 404, see if there is a redirect
		if @error?.statusCode == 404 and redirect = await @lookupRedirect()
		then return @$router.push redirect.to

		# Get the error page tower
		@page = await @loadErrorTower()

	# Show Sentry user input dialog if an error
	mounted: -> @showSentryDialog() if @error?.statusCode == 500

	computed:

		# Figure out which error Tower to show
		errorTowerUri: -> switch @error?.statusCode
			when 404 then 'not-found'
			else 'error'

	methods:

		# Show the sentry dialog
		# https://docs.sentry.io/enriching-error-data/user-feedback/?platform=browser
		showSentryDialog: -> @$defer => @$sentry?.showReportDialog()

		# Get the tower data
		loadErrorTower: -> @$craft.getEntry
			query: getTower
			variables:
				type: 'towers'
				uri: @errorTowerUri

		# Lookup whether there is a redirect for the current route
		lookupRedirect: -> @$craft.getEntry
			variables: from: await @getRoutePath()
			query: '''
				query lookupRedirect($from:[QueryArgument], $site:[String]) {
					entry(redirectFrom: $from, site:$site, section: "redirects") {
						... on redirects_redirects_Entry {
							to: redirectTo
						}
					}
				}
			'''

		# Wait a tick before reading the path because it's the previous route
		# on the intial page mount. Very weird.
		getRoutePath: -> new Promise (resolve) => @$defer => resolve @$route.path

