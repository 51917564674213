







































































































import QuickShopPurchase from './quick-shop-purchase'
import QuickShopModal from 'library/components/global/modals/quick-shop/quick-shop'
import StandardPurchase from 'library/components/global/product/purchasing/standard-purchase'
import SubscriberPurchase from 'library/components/global/product/purchasing/subscriber-purchase'
import Price from 'library/components/global/price'
import ProductFavorite from 'library/components/global/favorites/product-favorite'
import ProductBadge from 'library/components/global/product/badge'
import ProductCardBtnContainer from 'library/components/global/product-card/product-card-btn-container'
import PricespiderBtn from 'library/components/global/buttons/pricespider-btn'
import { mountOnBody } from 'library/services/helpers'
import { outOfStockRegex } from 'library/constants'
export default

	components: {
		QuickShopPurchase
		StandardPurchase
		SubscriberPurchase
		Price
		ProductFavorite
		ProductBadge
		ProductCardBtnContainer
		PricespiderBtn
	}

	props:

		# Product object
		product: Object

		# Default the image sizes to assuming a 4 column layout
		sizes:
			type: String
			default: '4 column'

		# Show using horzintal layout on mobile
		horizontalWhenSmall: Boolean

		# Whether to support subscription purchase options
		supportSubscriptions:
			type: Boolean
			default: false

	data: ->
		opened: false
		pricing: null # Used by subscriber-purchase on mobile

	computed:
		cypressIdentifier: -> "product-card-#{@product.title.split(' ').join('_').toLowerCase()}"

		# Only allow favoriting if you're on the domestic site
		canFavorite: -> process.env.CART_ENABLED

		showPurchaseOptions: -> @$cartEnabled

		# if international, then don't slide or reval
		# the CTA, as there's no shopify integration
		international: -> !@$cartEnabled

		# Adjust the font size when titles are long
		titleSizeClasses: -> 'small-title': @product.title.length > 40

		# Make classes
		classes: -> [
			"purchase-type-#{@$kebab(@purchaseState)}"
			'horizontal-when-small': @horizontalWhenSmall
			'opened' if @opened
		]

		# The product type is stored on different fields depending on the source
		# of the product object
		productLine: ->
			@product.productType or
			@product.type or
			@product.parent?.title

		# shorthand to get either the vendor or the parent from Craft
		productVendor: ->
			@product.vendor or
			@product.parent?.parent?.title

		# Get the product image reference, which may be in different places
		productImage: ->
			@product.image or
			@product.images or
			@product.media or
			@product.assetGroup?[0]?.image[0]

		# Figure out the prododuct url
		productUrl: ->
			if @international then "/products/#{@product.slug}"
			else "/products/#{@product.handle}"

		# Make the list of variants
		variants: -> @product.variants

		# Variant helper.  This data can live in different places if coming from
		# Storefront API or recommendations API
		variant: ->
			return unless variant = @variants[0]
			{
				...variant
				productNumer: variant.sku?.match(/^(\w+)\-/)?[1]
			}

		badge: -> @product.tags?.filter (name) -> name?.match /^Badge_/

		customer: -> @$store.getters['customer/navigation/accessToken']

		# Reduce the offerState to either a standard or a subscriber state
		purchaseState: ->
			if @customer and @supportSubscriptions then 'subscriber' else 'standard'

		# Which purchase options component to render
		purchaseComponent: ->
			if @supportSubscriptions
			then "#{@purchaseState}-purchase"
			else 'quick-shop'

		# Determine if the product is available
		available: ->
			return false if @hasOutOfStockTag
			@product.available or # Storefront GQL alias of availableForSale
			@product.availableForSale or # Natural Storefront GQL product property
			@product.inventory_available or # From Algolia via their shopify app
			@product.tags.includes 'SmartCommerce_In_Stock' # Smart Commerce Retailers

		ctaText: ->
			if @available
			then @$t('cart.quick_shop')
			else @$t('cart.unavailable')

		# Out of stock Shopify tag
		hasOutOfStockTag: ->
			@product?.tags?.some (badge) => outOfStockRegex.test badge

	methods:

		onClick: ->
			if @opened then @opened = false
			else @opened = true

		# Needed to open the quick shop modal from the mobile link
		openQuickModal: ->
			mountOnBody QuickShopModal,
				parent: this
				propsData: id: @product.id
