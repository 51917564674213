###
Inject global services and helpers into Nuxt
###
import * as storefront from 'library/services/shopify/storefront'
import * as helpers from 'library/services/helpers'
import { GTM_EVENTS, GTM_COMPONENTS } from 'library/constants'
import { captureSfmcQueryParams } from 'library/services/sfmc/conversion'
export default ({ app, error, query }, inject) ->

	# API Adapters
	inject 'storefront', storefront

	# Inject Helpers
	inject name, func for name, func of helpers

	# Inject some constants
	inject 'GTM_EVENTS', GTM_EVENTS
	inject 'GTM_COMPONENTS', GTM_COMPONENTS

	# Add the intited class to the page once mounting has occured
	helpers.extendApp app, mounted: -> helpers.addInittedClass()

	# Capture SFMC query params
	captureSfmcQueryParams query

	# Make 404 response
	inject 'notFound', -> error
		statusCode: 404
		message: 'Page not found'

	# Inject global var that can be used to conditionally enable cart UI. This
	# isn't stored in the cart plugin because that plugin is client only.
	inject 'cartEnabled', process.env.CART_ENABLED
