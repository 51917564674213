###
Assemble data used by cart components (full-cart, cart-flyout, etc)
###
import { formatMoney } from 'library/services/helpers'
import { FREE_SHIPPING_THRESHOLD } from 'library/constants'

export default

	computed:

		# Vuex helpers
		cart: -> @$store.state.cart
		lineItems: -> @$store.getters['cart/lineItems']
		subtotal: -> @$store.getters['cart/subtotal']
		hasItems: -> @$store.getters['cart/itemCount'] > 0
		checkoutUrl: -> @$store.getters['cart/checkoutUrl']
		freeShippingItems: -> @$store.state.globals.freeShippingItems?.products

		# Make discounts strings
		discounts: ->
			@$nonEmpty @cart.discountApplications
			.map (discount) ->
				return unless title = discount.code || discount.title
				value = switch
					when val = discount.value.amount then '-' + formatMoney val
					when val = discount.value.percentage then "-#{val}%"
				"#{title} #{value}"

		# Does the cart have discounts? If there are hidden items (like free gift)
		# variants, Shopify will have discount entries. So we return false since
		# in this case because the discount should be hidden.
		hasDiscount: ->
			if @cart.lineItems.length != @lineItems.length then return false
			return true if @discounts.length

		# A check against the vuex list of products which qualify the cart
		# for free shipping. If that product is in the cart, then
		# the whole cart gets free shipping.
		hasFreeShippingProduct: ->

			freeShippingTitles = []
			@freeShippingItems.forEach (item) ->
				freeShippingTitles.push item.title

			!!@lineItems.some (item) ->
				freeShippingTitles.includes(item.title)

		# Check if it goes over the free shipping threshold
		hasFreeShipping: ->
			if @hasFreeShippingProduct then return true
			else return @subtotal >= FREE_SHIPPING_THRESHOLD

		#	The amount remaining to get free shipping. Ex. 'You are <freeShippingThresholdAmount> Away From Free Shipping'
		freeShippingRemainingAmount: -> if !@hasFreeShipping then FREE_SHIPPING_THRESHOLD - @subtotal else 0

		# Find price until free shipping. $40 is the hardcoded value
		shippingCost: ->
			if @hasFreeShipping
			then 'Free'
			else
				remaining = formatMoney @freeShippingRemainingAmount
				"You're only " + remaining + " away from Free Shipping"

	# Fetch data if we don't have it yet
	mounted: -> @$store.dispatch 'cart/fetchUnlessHydrated'
