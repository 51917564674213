
































import AccountFlyout from '../../account-flyout'
import CustomerWelcome from 'library/components/account/customer-welcome'
import GuestTooltip from './guest-tooltip'
export default

	components: {
		CustomerWelcome
		GuestTooltip
	}

	props: iconOnly: Boolean

	data: ->
		open: false
		hasMounted: false

	# Used to delay client-only code. Using the `client-only` component resulted
	# in the transition between auth states not showing and I think it's worth
	# keeping.
	mounted: -> @$defer => @hasMounted = true

	computed:

		# VueX shorthands
		isAuthenticated: -> @$store.getters['customer/isAuthenticated']

		# Combine auth check with a delay for mounting so no SSR issue
		hasCustomer: -> @isAuthenticated and @hasMounted

	watch:

		# When a new product is favorited and the user isn't signed in, remind
		# them to sign in
		'$store.state.favorites.products': (val) ->
			return unless @$store.state.favorites.hydrated
			@open = true unless @isAuthenticated

	methods:

		# Handle clicks on the toggle button
		onToggleClick: ->
			if @isAuthenticated
			then @openAccountFlyout()
			else @open = !@open

		# Open the account flyout
		openAccountFlyout: ->
			return if @open
			flyout = await @$mountOnBody AccountFlyout, parent: this
			flyout.$once 'close', => @$wait 250, => @open = false
			@open = true
