import { render, staticRenderFns } from "./carousel.vue?vue&type=template&id=7c5eac8c&scoped=true&lang=pug&"
import script from "./carousel.vue?vue&type=script&lang=coffee&"
export * from "./carousel.vue?vue&type=script&lang=coffee&"
import style0 from "./carousel.vue?vue&type=style&index=0&id=7c5eac8c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c5eac8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
