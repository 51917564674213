###
Helpers for interacting with the Storefront account APIs. One goal is to
standardize the response body and erro\r handling
###

# Packages
import axios from '../axios'
import { getRecaptchaToken } from 'library/services/helpers'
import { execute } from './storefront'

# Queries
import activateQuery from 'library/queries/shopify/customer/activate.gql'
import addressCreateQuery from 'library/queries/shopify/customer/address-create.gql'
import addressDeleteQuery from 'library/queries/shopify/customer/address-delete.gql'
import addressesQuery from 'library/queries/shopify/customer/addresses.gql'
import addressUpdateDefaultQuery from 'library/queries/shopify/customer/address-update-default.gql'
import addressUpdateQuery from 'library/queries/shopify/customer/address-update.gql'
import createQuery from 'library/queries/shopify/customer/create.gql'
import fetchQuery from 'library/queries/shopify/customer/fetch.gql'
import loginQuery from 'library/queries/shopify/customer/login.gql'
import logoutQuery from 'library/queries/shopify/customer/logout.gql'
import orderQuery from 'library/queries/shopify/customer/order.gql'
import ordersQuery from 'library/queries/shopify/customer/orders.gql'
import orderStatusesQuery from 'library/queries/shopify/customer/order-statuses.gql'
import recoverQuery from 'library/queries/shopify/customer/recover.gql'
import renewQuery from 'library/queries/shopify/customer/renew.gql'
import resetPasswordQuery from 'library/queries/shopify/customer/reset-password.gql'
import updateQuery from 'library/queries/shopify/customer/update.gql'

# Standard method for returning the mutation payload and handling customer
# errors
executeMutation = (queryPayload) ->

	# Execute the request
	{ mutation } = await execute queryPayload

	# Handle error messages by converting known ones to strings and
	# returning the messages otherwise
	if mutation.userErrors?.length
	then throw new AggregateError mutation.userErrors.map (error) ->
		switch error.code
			when 'UNIDENTIFIED_CUSTOMER' then 'Incorrect email or password.'
			else error.message

	# On success, return the data property if it exits. Else, return the full
	# mutation object
	return mutation.data || mutation

# Register a new customer using Netlify function that validates recaptcha
export create = ({ email, password, firstName, lastName, acceptsMarketing }) ->
	url = "#{process.env.NUXT_APP_URL}/.netlify/functions/create-customer"
	{ data } = await axios.post url,
		recaptchaToken: await getRecaptchaToken()
		customer: { email, password, firstName, lastName, acceptsMarketing }
	if (errors = data.errors || data?.userErrors).length
	then throw new AggregateError errors.map (error) -> error.message

# Trigger a password reset
export recoverPassword = ({ email }) ->
	executeMutation
		query: recoverQuery
		variables: email: email

# Get the access token
export login = ({ email, password }) ->
	executeMutation
		query: loginQuery
		variables: input: { email, password }

# Validate and refresh the access token expiration
export renew = ({ accessToken }) ->
	executeMutation
		query: renewQuery
		variables: { accessToken }

# Get a customer given their access token
export fetch = ({ accessToken }) ->
	response = await execute
		query: fetchQuery
		variables: { accessToken }
	return response.customer

# Update customer data
export update = ({ accessToken, customer }) ->
	executeMutation
		query: updateQuery
		variables: { accessToken, customer }

# Delete a user's session
export logout = ({ accessToken }) ->
	execute
		query: logoutQuery
		variables: { accessToken }

# Get a customer's addresses
export getAddresses = ({ accessToken, perPage = 12, nextCursor }) ->
	response = await execute
		query: addressesQuery
		variables: { accessToken, perPage, nextCursor }
	response.customer.addresses

# Get a customer's orders (expecting pagination)
export getOrders = ({
	accessToken
	perPage = 12
	previousCursor
	nextCursor
}) ->

	# Build variables based on the presence of next or previous cursors
	variables = { accessToken }
	switch
		when nextCursor
			variables = { ...variables, first: perPage, after: nextCursor }
		when previousCursor
			variables = { ...variables, last: perPage, before: previousCursor }
		else variables = { ...variables, first: perPage }

	# Get list of orders
	response = await execute { query: ordersQuery, variables }

	# Manually flatten the orders objects to preserve the cursor and the
	# pageInfo object
	orders = (response.customer.orders.items || [])
	.map ({ cursor, node }) -> { ...node, cursor }
	.map massageOrder

	# Return the previous or next cursor value if there are pages in that
	# direction
	{ hasPreviousPage, hasNextPage } = response.customer.orders.pageInfo
	previousCursor = if hasPreviousPage then orders[0]?.cursor else null
	nextCursor = if hasNextPage then orders[orders.length - 1]?.cursor else null

	# Return object with orders and the data needed to paginate
	{ orders, previousCursor, nextCursor }

# Get just the statuses of orders
export getOrderStatuses = ({ accessToken }) ->
	response = await execute
		query: orderStatusesQuery
		variables: { accessToken }
	return response.customer.orders

# Get a single order
export getOrder = ({ id, accessToken }) ->
	response = await execute
		query: orderQuery
		variables: { id, accessToken }
	return massageOrder response.order

# Massage order data to be more useable
massageOrder = (order) ->

	# Flatten currency values of order
	for key, val of order
		if val?.amount? then order[key] = parseFloat val.amount

	# Also flatten the shipping discount amounts
	order.shippingDiscountAllocations = (order.shippingDiscountAllocations ||
		[]).map (allocation) -> {
			...allocation
			amount: parseFloat allocation.amount.amount
		}

	return order

# Create an address
export createAddress = ({ address, accessToken }) ->
	executeMutation
		query: addressCreateQuery
		variables: { accessToken, address }

# Update an address
export updateAddress = ({ id, address, accessToken }) ->
	executeMutation
		query: addressUpdateQuery
		variables: { accessToken, address, id }

# Update the default address of a customer
export updateDefaultAddress = ({ id, accessToken }) ->
	executeMutation
		query: addressUpdateDefaultQuery
		variables: { accessToken, id }

# Delete an address
export deleteAddress = ({ id, accessToken }) ->
	executeMutation
		query: addressDeleteQuery
		variables: { accessToken, id }
