import * as cartApi from 'library/services/shopify/cart'
import {
	BUNDLE_ATTRIBUTE_KEY,
	BUNDLES_CART_ATTRIBUTE_KEY,
} from 'library/constants'
import { getLineBundleSku } from 'library/services/shopify/bundling/rollupLines'

# Sync the state of bundles in the cart to cart attributes for reading by
# web pixels. This is necessary because web pixels can't read line attributes,
# only cart attributes
# https://community.shopify.com/c/extensions/web-pixel-s-checkoutlineitem-object-should-include-line-item/td-p/2131920
export syncBundlesToCartAttributes = (store) ->

	# Watch for actions that mutate bundles. I originally tried to watch the
	# `state.cart.lineItems` but preventing endlessly triggering this function
	# was a challenge.  I'd need to do some checking for what actually changed.
	store.subscribeAction after: ({ type, payload }) ->
		if type in ['cart/addBundle', 'cart/updateBundleLine']
		then handleBundlesUpdate()

	# Handle the watcher on lineItems change
	handleBundlesUpdate = ->
		{ cart } = store.state

		# Make the bundles attribute value
		bundlesAttribute = makeAttributeValue cart

		# Abort if no change
		return unless haveBundlesAttributeChanged cart, bundlesAttribute

		# Make the cart attributes, merging the bundle attributes into any existing
		# ones since the mutation replaces.
		attributes = mergeBundlesAttribute cart, bundlesAttribute

		# Write the change and return the updated cart
		try
			cart = await cartApi.updateCartAttributes
				cartId: cart.id
				attributes: attributes
			store.commit 'cart/replace', cart
		catch e then console.error e

	# Produce an array of all of the variants in lines that comprise bundles
	# and what their bundle SKU is.  This can be use to reconstruct the
	# line attributes by the gtm custom web pixel
	makeAttributeValue = (cart) ->
		cart.lineItems.reduce (bundlesAttribute, line) ->
			return bundlesAttribute unless bundleSku = getLineBundleSku line
			return [ ...bundlesAttribute, {
				variantId: line.variant.id
				quantity: line.quantity
				bundleSku
			}]
		, []

	# Check if the bundles attribute would change
	haveBundlesAttributeChanged = (cart, bundlesAttribute) ->
		currentBundlesAttribute = cart.attributes.find ({ key }) ->
			key == BUNDLES_CART_ATTRIBUTE_KEY
		return JSON.stringify(bundlesAttribute) != currentBundlesAttribute?.value

	# Merge updated bundles attribute. If there are no bundlesAttribute,
	# this removes the attribute alotogether.
	mergeBundlesAttribute = (cart, bundlesAttribute) ->
		cartAttributesWithoutBundles = cart.attributes.filter ({ key }) ->
			key != BUNDLES_CART_ATTRIBUTE_KEY
		if bundlesAttribute?.length
		then [
			...cartAttributesWithoutBundles
			{
				key: BUNDLES_CART_ATTRIBUTE_KEY
				value: JSON.stringify bundlesAttribute
			}
		]
		else cartAttributesWithoutBundles
